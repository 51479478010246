@mixin clearfix {
	&::after {
		content: "";
		display: block;
		clear: both;
	}
}

@mixin trns($property: all, $speed: .5s, $easing: ease) {
	transition: $property $speed $easing;
}

@mixin serifBasic {
	font-family: $serif;
	line-height: 1.5;
	// 	letter-spacing: .075vw;
	letter-spacing: .2vw;
}

$mq-breakpoints: (
	'pcmin': 'min-width: '+ #{1 + $bpTb},
	'pcmax': 'max-width: '+ $innerW,
	'tb': 'max-width: '+ $bpTb,
	'sp': 'max-width: '+ $bpSp,
);

@mixin mq($breakpoint: tb) {
	@media screen and (#{map-get($mq-breakpoints, $breakpoint)}) {
		@content;
	}
}

@mixin flex {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;

	@include mq(sp) {
		display: block;
	}
}

@mixin clearfix {
	display: block;
	&:after {
		content: "";
		display: block;
		clear: both;
	}
	&:before {
		content: "";
		display: block;
		clear: both;
	}
}

/*--------------------

	underline

--------------------*/

@mixin underline($color:$white) {
	position: relative;
	&:after {
		content: "";
		display: block;
		position: absolute;
		border-bottom: 2px solid $color;
		width: 0;
		left: 100%;
		bottom: -2px;
		opacity: 0.5;
		transition: $tr05s;
	}
	&:hover {
		&:after {
			width: 100%;
			left: 0;
			opacity: 1;
			transition: $tr2s;
		}
	}
}


/*--------------------
	arrows
--------------------*/
@mixin arrow($direction, $color, $height, $width) {
	// Base styles for all triangles
	border-style: solid;
	height: 0;
	width: 0;
	@if($direction == 'top') {
		border-color: transparent transparent $color transparent;
		border-width: 0 ($width/2) $height ($width/2);
	}
	@if ($direction == 'bottom') {
		border-color: $color transparent transparent transparent;
		border-width: $height ($width/2) 0 ($width/2);
	}
	@if ($direction == 'right') {
		border-color: transparent transparent transparent $color;
		border-width: ($height/2) 0 ($height/2) $width;
	}
	@if ($direction == 'left') {
		border-color: transparent $color transparent transparent;
		border-width: ($height/2) $width ($height/2) 0;
	}
	// Ordinal Directions - can be scalene this way!
	@if ($direction == 'top-left') {
		border-color: $color transparent transparent transparent;
		border-width: $height $width 0 0;
	}
	@if ($direction == 'top-right') {
		border-color: transparent $color transparent transparent;
		border-width: 0 $width $height 0;
	}
	@if ($direction == 'bottom-left') {
		border-color: transparent transparent transparent $color;
		border-width: $height 0 0 $width;
	}
	@if ($direction == 'bottom-right') {
		border-color: transparent transparent $color transparent;
		border-width: 0 0 $height $width;
	}
}

/*1.フェードインアニメーションの指定*/
.scrollanime {opacity: 0;} /*一瞬表示されるのを防ぐ*/
.fadeInDown {
    animation-name: fadeInDown;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
}
@keyframes fadeInDown {
    0% {
        opacity: 0;         
    }
    100% {
    opacity: 1;
    transform: translate(0);
    }
}
 
/*2.上下の動きを指定*/
.updown {transform: translateY(-30px);}
.downup {transform: translateY(30px);}
 
/*3.左右の動きを指定*/
.sect02{overflow: hidden;} /*横スクロールバーを隠す*/
.slide-right {transform: translateX(200px);}
.slide-left {transform: translateX(-200px);}


/*--------------------
	arrows
--------------------*/
@mixin arrow($direction, $color, $height, $width) {
	// Base styles for all triangles
	border-style: solid;
	height: 0;
	width: 0;
	@if($direction == 'top') {
		border-color: transparent transparent $color transparent;
		border-width: 0 ($width/2) $height ($width/2);
	}
	@if ($direction == 'bottom') {
		border-color: $color transparent transparent transparent;
		border-width: $height ($width/2) 0 ($width/2);
	}
	@if ($direction == 'right') {
		border-color: transparent transparent transparent $color;
		border-width: ($height/2) 0 ($height/2) $width;
	}
	@if ($direction == 'left') {
		border-color: transparent $color transparent transparent;
		border-width: ($height/2) $width ($height/2) 0;
	}
	// Ordinal Directions - can be scalene this way!
	@if ($direction == 'top-left') {
		border-color: $color transparent transparent transparent;
		border-width: $height $width 0 0;
	}
	@if ($direction == 'top-right') {
		border-color: transparent $color transparent transparent;
		border-width: 0 $width $height 0;
	}
	@if ($direction == 'bottom-left') {
		border-color: transparent transparent transparent $color;
		border-width: $height 0 0 $width;
	}
	@if ($direction == 'bottom-right') {
		border-color: transparent transparent $color transparent;
		border-width: 0 0 $height $width;
	}
}