// units
$unit: 7px;
$innerW: $unit * 68; // 1088px
$bpipadpro: 1024px;
$bpTb: 767px;
$bpSp: 599px;
$headerH: 74px;
//$spHeaderH:	80px;

// fonts
$dosis: 'franklin-gothic-atf',
sans-serif;
// colors
$bk01: rgba(0, 0, 0, 1); // キンクロ	
$wt01: rgba(255, 255, 255, 1); // ホワイト
$gray: #EBEBEB; //  gray
$grayfont: #909090;
$bg01: #B4B5A5;
$br01: #504846;

// ob colors
$paleblue: #DFE9EF;
$dullgreen: #75B7A5;
$salmon: #E35A5A;
$dgray: #2E2E2E;
$gray: #797979;
$lgray: #939393;
$llgray: #eaeaea;
$obblue:#171C61;

// transition
$tr05s: all .5s ease;
$tr1s: all 1s ease;
$tr2s: all 2s ease;


@mixin sp16() {
	font-size: 18px;

	@include mq(sp) {
		font-size: 16px;
	}
}

@mixin sp14() {
	font-size: 16px;

	@include mq(sp) {
		font-size: 14px;
	}
}

@mixin sp13() {
	font-size: 15px;

	@include mq(sp) {
		font-size: 13px;
	}
}

@mixin sp12() {
	font-size: 14px;

	@include mq(sp) {
		font-size: 12px;
	}
}