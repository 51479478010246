body {
	overflow-x: hidden;

	&:not(.home) {
		background: linear-gradient(#DFE9EF, #DFE9EF, #ffffff);
		>section {
			&:not(:last-child) {
				padding: 0 0 $unit *10;
			}
		}
	}
}

header {
	max-width: 1186px;
	height: 73px;
	margin: 0 auto;
	background: #fff;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 $unit * 3.5;
	border-radius: 4px;
	position: fixed;
	width: 100%;
	top: 31px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 10;
	box-shadow: 0 0 10px rgba($obblue, 0.2);

	.header_nav {
		display: flex;
		justify-content: flex-end;
		i.underline {
			position: absolute;
			bottom: 0;
			height: 2px;
			background-color: $obblue;
			transition: $tr05s;
			z-index: -1;
		}
		.header_nav_li {
			display: block;

			.header_nav_big {
				display: none;
				position: absolute;
				top: 73px;
				left: $unit / 2;
				width: calc( 100% - #{$unit} );

				.wrapper {
					background: rgba(23, 28, 97, 0.87);
					padding: $unit * 7.5 $unit * 20 $unit * 7.5;
					border-top-left-radius: 0;
					border-top-right-radius: 0;

					h3 {
						color: #fff;
						font-size: 28px;

						a {
							color: #fff;
						}

						span {
							color: #B7B7B7;
							font-size: 12px;
							display: block;
							margin-bottom: 12px;

							&::after {
								content: url(../img/arrow_next.svg);
								position: relative;
								right: -12px;
								top: -1px;
							}
						}
					}

					.header_nav_big_links {
						max-width: 635px;

						.header_nav_big_link {
							width: calc( 50% - #{$unit * 4} );
							margin-bottom: $unit * 3;

							&:last-child {
								margin-bottom: 0;
							}

							a {
								color: #fff;
								padding: 20px 40px;
								display: flex;
								align-items: center;
								justify-content: center;
								border-bottom: 2px solid #fff;
								position: relative;
								font-size: 14px; //開発用一時的
								letter-spacing: 0;

								&::after {
									content: url(../img/arrow_next.svg);
									position: absolute;
									right: 5px;
									top: 50%;
									transform: translateY(-50%);
									transition: .3s;
								}

								&:hover {
									&::after {
										right: 0;
										transition: .3s;
									}
								}
							}

							&.is_small {
								a {
									font-size: 13px;
								}
							}
						}
					}
				}
			}

			>a {
				font-size: 14px;
				color: $obblue;
				font-weight: 600;
				height: 73px;
				padding: 0 $unit * 3;
				line-height: 73px;
				display: block;
				border-bottom: 2px solid transparent;
				transition: $tr05s;
			}

			&:hover {
				a {
					// border-bottom-color: $obblue;
				}

				// .header_nav_big {
				// 	display: block;
				// 	visibility: visible;
				// 	opacity: 1;
				// 	transition: 0s;

				// }
			}
		}
	}
}

.kv {
	overflow: hidden;
	background: url(../img/kv.jpg);
	height: 100vh;
	width: 100%;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.5);
	}

	video {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: auto;
		height: auto;
		min-width: 100%;
		min-height: 100%;
		// background: url('../img/kv.jpg') no-repeat;
		background: #000;
		background-size: cover;
	}

	.kv_guidance {
		position: absolute;
		bottom: 60px;
		left: 12%;
		z-index: 1;

		.kv_guidance_title {
			background: #fff;
			color: $obblue;
			width: auto;
			height: auto;
			display: inline-block;
			justify-content: center;
			align-items: center;
			font-weight: bold;
			font-size: 14px;

			a {
				display: inline-block;
				line-height: 1;
				padding: 1rem 2rem 1rem 1rem;
				color: $obblue;
				position: relative;

				&::after {
					content: url(../img/arrow_next_blue.svg);
					position: absolute;
					right: 1rem;
					top: 50%;
					transform: translateY(-50%);
					transition: .3s;
				}

				&:hover {

					&::after {
						right: calc(1rem - 5px);
						transition: .3s;
					}
				}
			}
		}

		h1 {
			font-size: 32px;
			font-weight: 600px;
			margin-bottom: 28px;
		}
	}

	transition: .3s;

	&.nav_open {
		transform: translate3d(-100%, 0, 0);
		transition: .3s;
	}
}


main {
	padding: $unit * 20 0;
	min-width: $unit * 169; // 1183px
	// transition: .3s; 必要かどうか要確認
	// position: relative; 必要かどうか要確認

	&.nav_open {
		transform: translate3d(-100%, 0, 0);
		transition: .3s;
	}
	@media (max-width: 1183px) {
		min-width: auto;
	}
}

.about {
	background: url(../img/about_bg.jpg);
	background-size: cover;
	padding-top: 74px;
	padding-bottom: 144px;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(23, 28, 97, 0.9);
	}

	.about_contents {
		position: relative;
		z-index: 1;
		max-width: 1035px;
		margin: 0 auto;

		.about_fir {
			color: #fff;
			margin-bottom: 48px;

			img {
				margin-right: 15px;
			}
		}

		h1 {
			text-align: center;
			font-size: 32px;
			margin-bottom: 46px;
			line-height: 1.5;
		}

		.button02_ul {
			max-width: 667px;
			margin: 0 auto;

			.button02 {
				width: 100%;
				max-width: 185px;
				border-bottom: 2px solid #fff;

				a {
					color: #fff;
					font-weight: 300;
					display: block;
					text-align: center;
					position: relative;
					padding: 20px;

					&::after {
						content: url(../img/arrow_next.svg);
						position: absolute;
						right: 0;
						top: 50%;
						transform: translateY(-50%);
						transition: .3s;
					}

					&:hover {

						&::after {
							right: -5px;
							transition: .3s;
						}
					}
				}
			}
		}
	}
}

.news {
	.sec_title {
		max-width: 1012px;
		margin-right: auto;
		margin-left: auto;
	}

	.news_wrap {
		padding: 0 8px;
		max-width: 1012px;
		margin: 0 auto;
		margin-bottom: 32px;

		.news_content {
			padding: 32px 0;
			border-bottom: 1px solid $llgray;


			.news_info {
				color: $lgray;
				font-size: 11px;
				margin-bottom: 21px;

				a {
					color: $obblue;
					font-size: 12px;
				}
			}

			.text01 {
				a {
					color: $dgray;
				}
			}


			&:last-child {
				border-bottom: none;
			}
		}
	}

	.btm_na_btn {
		max-width: 1012px;
		margin: 0 auto;
		text-align: right;

		.button02 {
			color: $dgray;
			font-size: 14px;

			&::after {
				content: url(../img/arrow_next_blue.svg);
				height: 28px;
				width: 28px;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				background: #E7E7EF;
				margin-left: 32px;
			}
		}
	}
}

.recruit {
	padding-top: 32px;

	.recruit_content {
		.recruit_content_desc {
			width: 58%;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;

			&.recruit_content_desc01 {
				background: url(../img/recruit_bg01.jpg) no-repeat 50% 50%;
				&::after {
					content: '';
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					background-color: rgba(43, 52, 173, 0.9);
				}
			}

			&.recruit_content_desc02 {
				background: url(../img/recruit_bg02.jpg) no-repeat 50% 50%;
				&::after {
					content: '';
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					background-color: rgba(6, 92, 68, 0.9);
				}
			}

			.recruit_content_desc_inner {
				position: relative;
				z-index: 1;
				color: #fff;
				text-align: center;

				span {
					color: #B7B7B7;
					font-size: 12px;
					margin-bottom: 32px;
					display: inline-block;
				}

				h5 {
					font-size: 20px;
					line-height: 1.75;
					margin-bottom: 24px;

				}
			}
		}

		.recruit_content_img {
			width: 42%;
		}
	}
}

.about {
	background: url(../img/about_bg.jpg);
	background-size: cover;
	padding-top: 74px;
	padding-bottom: 144px;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(23, 28, 97, 0.9);
	}

	.about_contents {
		position: relative;
		z-index: 1;
		max-width: 1035px;
		margin: 0 auto;

		.about_fir {
			color: #fff;
			margin-bottom: 48px;

			img {
				margin-right: 15px;
			}
		}

		h1 {
			text-align: center;
			font-size: 32px;
			margin-bottom: 46px;
			line-height: 1.5;
		}

		.button02_ul {
			max-width: 667px;
			margin: 0 auto;

			.button02 {
				width: 100%;
				max-width: 185px;
				border-bottom: 2px solid #fff;

				a {
					color: #fff;
					font-weight: 300;
					display: block;
					text-align: center;
					position: relative;
					padding: 20px;

					&::after {
						content: url(../img/arrow_next.svg);
						position: absolute;
						right: 0;
						top: 50%;
						transform: translateY(-50%);
						transition: .3s;
					}

					&:hover {

						&::after {
							right: -5px;
							transition: .3s;
						}
					}
				}
			}
		}
	}
}

.messages {
	padding-top: 108px;
	padding-bottom: 80px;
	background: #fff;

	.min_wrap {
		h2 {
			font-size: 28px;
			margin-bottom: 72px;
			text-align: center;

			span {
				color: #B7B7B7;
				font-size: 12px;
				display: block;
				margin-bottom: 16px;
			}
		}

		.text01 {
			margin-bottom: 72px;
			text-align: center;
			line-height: 1.5;
		}

		.message_cards {
			.message_card {
				max-width: 360px;

				&.message_card_wide {
					width: 100%;
					display: flex;
					max-width: none;
					margin-bottom: 72px;

					.message_card_img {
						max-width: 226px;
					}

					.flex {
						align-items: center;
						justify-content: center;
						width: 100%;
						background: $llgray;
						height: auto;
					}
				}

				.flex {
					background: $llgray;
					height: 58px;
					align-items: center;
					justify-content: center;

					.text01 {
						margin: 0;
					}
				}

				.message_card_img {
					overflow: hidden;

					img {
						transition: .3s;
					}
				}

				&:hover {
					.message_card_img {
						img {
							transform: scale(1.1);
							transition: $tr05s;
						}
					}
				}
			}
		}
	}
}

.message_videos {
	background: linear-gradient(#DFE9EF, #DFE9EF, #ffffff);
	padding: 72px 0;

	.wrapper {

		.flex {
			width: 100%;
			background: $llgray;
			justify-content: start;
			width: calc(100% + 198px);
			margin-left: -99px;
			margin-bottom: 36px;

			li {
				.text01 {
					display: block;
					padding: 21px 36px;
				}
			}
		}

		.massage_video_content {
			border-bottom: 1px solid $llgray;

			a {
				padding: 36px 0;
				display: block;

				.massage_video_desc {
					font-size: 12px;
					margin-bottom: 21px;

					span {
						font-size: 11px;
						color: $lgray;
					}
				}
			}
		}

		.btm_btns {
			max-width: 285px;
			margin: 0 auto;
			margin-top: 108px;

			ul {
				display: flex;
				justify-content: space-between;
				align-items: center;

				li {
					a {
						color: $obblue;
					}

					&.button04 {
						a {
							color: #fff;
							width: 100px;
							height: 30px;
							background: $obblue;
							display: flex;
							align-items: center;
							justify-content: center;
						}
					}
				}
			}
		}
	}
}

.news_detail {
	.wrapper {

		.news_detail_nav {
			margin-top: 144px;
			align-items: center;

			li {
				width: calc(33% - #{$unit * 2});
				position: relative;

				&.news_detail_nav_back {
					&::before {
						content: url(../img/arrow_back.svg);
						position: absolute;
						transform: translateY(-50%);
						top: 50%;
						left: 0;
					}

					a {
						padding-left: 16px;
					}
				}

				&.news_detail_nav_next {
					&::after {
						content: url(../img/arrow_next_black.svg);
						position: absolute;
						transform: translateY(-50%);
						top: 50%;
						right: 0;
					}

					a {
						padding-right: 16px;
					}
				}

				&.back {
					text-align: center;
				}

				a {
					display: block;
					color: $dgray;
					// max-width: 261px;
					font-weight: 200;
					line-height: 1.5;
				}
			}
		}
		p {
			+ h3 {
				margin-top: $unit * 7;
			}
		}
	}
}

.senior_list {
	background: linear-gradient(#DFE9EF, #DFE9EF, #ffffff);
	padding: 72px 0;

	.wrapper {

		h2 {
			font-size: 28px;
			margin-bottom: 72px;
		}

		ul {
			li {
				&:not(:last-child) {
					margin-bottom: 32px;
				}

				.card03 {
					.card03_desc {
						background: #DFE9EF;
					}
				}

				&.gray {
					background: $llgray;
				}

				&:nth-child(even) {
					.card03_desc {
						background: $llgray;
					}
				}
			}
		}
	}
}

.senior_employee {
	.sec_title02 {
		h2 {
			margin-top: 0;
		}
	}

	.wrapper {
		.senior_employee_kv {
			background: url(../img/senior_employee_kv_test.jpg) 100% 100% / cover;
			height: 422px;
			width: calc(100% + 198px);
			margin-left: -99px;
			margin-bottom: 72px;
		}

		.senior_employee_wrap {
			display: flex;
			justify-content: space-between;

			.senior_employee_title {
				font-size: 22px;
				color: $obblue;
			}

			.senior_employee_inner {
				width: 78%;

				h4 {
					font-size: 20px;
					color: $obblue;
					margin-bottom: 40px;
					border-bottom: none;
					padding: 0;
					display: block;
				}

				.text01 {
					margin-bottom: 48px;
				}

				.text02 {
					margin-bottom: 48px;
				}

				h5 {
					display: inline-block;
					font-weight: 400;
					border-bottom: 2px solid $obblue;
					margin-bottom: 24px;
					padding: 0 3px 10px;
				}

				strong {
					color: $obblue;
					font-size: 14px;
					margin-bottom: 24px;
					font-weight: 400;
					display: block;
				}
			}
		}
	}
}

.chronology {
	.wrapper {
		.chronology_wrap {

			strong {
				font-weight: 500;
			}

			li {
				width: 100%;
				margin-bottom: $unit * 2;
				padding: $unit;
				border-bottom: 1px solid rgba($obblue, 0.1);

				&:last-child {
					margin-bottom: 0;
				}

				span {
					width: 10%;
				}

				.tpix {
					width: 46.4%;
					font-size: 14px;
					color: $obblue;
				}

				.tenpo_tenkai {
					width: 24%;
					font-size: 14px;
					color: $obblue;
				}

				.day {
					width: 10%;
					font-size: 18px;
				}

				.tpix_width {
					width: 46.4%;
				}

				.tenpo_tenkai_width {
					width: 24%;
				}

				.text01 {
					font-size: 16px;
				}
			}
		}
	}
}

.resident_info {

	.container {
		background: $paleblue;
		padding: 4rem 2rem;
		text-align: center;

		.text01 {
			margin-top: 2rem;
		}

		+.sec_title02 {
			margin-top: $unit * 10;
		}
	}
}

.business_goal {
	background: linear-gradient(#DFE9EF, #DFE9EF, #ffffff);
	padding: 72px 0;

	.wrapper {
		.business_goal_title {
			display: flex;
			width: 100%;
			justify-content: center;
			align-items: center;
			color: $obblue;
			border: 1px solid $obblue;
			font-size: 22px;
			height: 72px;
			font-weight: 500;
			margin-bottom: 72px;
		}

		.business_goal_sec02 {
			.plus_margin {
				margin-bottom: 72px;
			}
		}

		.business_goal_h3 {
			font-size: 22px;
			color: $obblue;
			font-weight: 500;
			margin-bottom: 32px;
			display: flex;
			align-items: center;

			&::before {
				background: #DFE9EF;
				font-size: 32px;
				height: 56px;
				width: 56px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				margin-right: 16px;
			}

			&.business_goal_disc_01 {
				&::before {
					content: "1";
				}
			}

			&.business_goal_disc_02 {
				&::before {
					content: "2";
				}
			}

			&.business_goal_disc_03 {
				&::before {
					content: "3";
				}
			}

			&.business_goal_disc_04 {
				&::before {
					content: "4";
				}
			}

			&.business_goal_disc_05 {
				&::before {
					content: "5";
				}
			}

			&.business_goal_disc_06 {
				&::before {
					content: "6";
				}
			}

			&.business_goal_disc_07 {
				&::before {
					content: "7";
				}
			}

			&.business_goal_disc_08 {
				&::before {
					content: "8";
				}
			}

			&.business_goal_disc_09 {
				&::before {
					content: "9";
				}
			}
		}
	}
}

.overview {
	.wrapper {
		dl {
			line-height: 1.5;

			dt,
			dd {
				margin-bottom: 72px;

				&:last-child {
					margin-bottom: 0;
				}
			}

			dt {
				width: 112px;

				strong {
					font-weight: 400;
					font-size: 18px;
				}
			}

			dd {
				width: calc(100% - 200px);
				font-size: 18px;
			}

			&.overview_2nd {

				dt,
				dd {
					display: flex;
					align-items: center;
				}

				dt {
					width: 25%;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				dd {
					width: calc(75% - 72px);

				}
			}
		}
	}
}

.board_member_introduction {

	.wrapper {
		.board_member_introduction_content {
			display: flex;
			justify-content: flex-end;
			margin-bottom: 72px;
			align-items: start;

			&:last-child {
				margin-bottom: 0;
			}

			.board_member_introduction_img {
				width: 228px;
				object-fit: contain;
			}

			.board_member_introduction_desc {
				padding-left: 77px;
				width: calc(100% - 228px);

				h3 {
					color: $obblue;
					font-size: 22px;
					font-weight: 400;
					margin-bottom: 36px;

					span {
						display: block;
						font-size: 12px;
						margin-bottom: 16px;
					}
				}

				.text01 {
					font-weight: 300;
				}
			}
		}
	}
}

.list_of_bases {
	.wrapper {
		.list_of_bases_f {
			margin-bottom: 72px;
		}

		.base_links {
			margin-bottom: 144px;

			.bases_map {
				max-width: 384px;
			}

			.base_links_all {
				width: calc(100% - 384px);
				padding-left: 64px;

				a {
					line-height: 2.5;
					color: $dgray;
					font-size: 14px;

					&::after {
						content: url(../img/diagonal_line.svg);
						position: relative;
						top: 5px;
						left: 3px;
					}

					&:last-child {
					&::after {
							content: none;
						}
					}
				}
			}
		}

		.base_desc_wrap {
			.bases_desc {
				width: 50%;

				h3 {
					font-size: 22px;
					color: $obblue;
					font-weight: 400;
					margin-bottom: 32px;
				}

				.button05 {
					margin-top: 36px;
				}
			}

			.base_img {
				width: calc( 50% - #{ $unit * 4} );
				img {
					width: 100%;
				}
			}
		}

		.flex {
			.bases {
				width: calc( 50% - #{$unit * 4 });
				margin-bottom: 4rem;
				display: flex;
				justify-content: space-between;

				.bases_inner {
					padding-right: 10px;

					h3 {
						font-size: 22px;
						color: $obblue;
						font-weight: 400;
						margin-bottom: 32px;
						a {
							color: $obblue;
						}
					}

					.text01 {
						font-size: 14px;
						margin-bottom: $unit * 2;
					}
				}

				.base_img {
					width: calc( 50% - #{ $unit * 4} );
					img {
						width: 100%;
					}
				}
			}
		}
	}
}

.breadcrumb_trail {
	width: 100%;
	padding: $unit * 2 0;
	background: #DFE9EF;
	position: relative;

	ul {
		max-width: 1187px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		flex-wrap: wrap;

		li {
			// margin-right: 16px;
			@include flex;

			@include mq(tb) {
				display: inherit;
				margin: $unit 0;
			}

			span,
			a {
				position: relative;
				font-size: 12px;
			}

			span {
				color: #2E2E2E;
			}

			a {
				color: #939393;
				transition: $tr05s;

				&:hover {
					color: #000;
				}
			}

			&::after {
				content: url(../img/diagonal_line_w.svg);
				position: relative;
				margin: 0 $unit;
			}

			&:last-child {

				&::after {
					content: none;
				}
			}
		}
	}
}

footer {
	background: $obblue;
	transition: .3s;
	position: relative;

	&.nav_open {
		transform: translate3d(-100%, 0, 0);
		transition: .3s;
	}

	.wrapper {
		background: $obblue;
		padding-top: $unit * 10;
		padding-bottom: $unit * 10;
		.footer_wrap_left {
			.footer_logo {
				margin-bottom: $unit * 5;
			}

			.text03 {
				color: #fff;
				font-size: 12px;
				line-height: 1.5;
			}
		}

		.footer_wrap_right {
			text-align: right;
			width: 42rem;
			font-size: $unit * 2;

			.footer_wrap_right_inner {
				margin-bottom: $unit * 4;

				a {
					color: #fff;
					line-height: 2.5;
					margin-left: 1rem;
					display: inline-block;
				}
			}

			.footer_wrap_right_sns {
				margin-bottom: $unit * 6;
				align-items: center;
				justify-content: end;

				p {
					color: #fff;
					font-weight: 400;
				}

				.footer_sns {
					margin-left: $unit * 4;
				}
			}

			small {
				color: #fff;
				font-size: 11px;
				display: block;
				text-align: right;
			}
		}
	}
}

form {
	background: $llgray;
	padding: 72px;

	dl {
		max-width: 730px;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 0 auto;

		dt,
		dd {
			margin-bottom: $unit * 4;
		}

		dt {
			width: 12rem;

			.red {
				font-size: 12px;
			}
		}

		dd {
			width: calc(100% - 12rem - 2rem);

		}

		input,
		select,
		textarea {
			border: none;
			width: 100%;
		}

		input,
		select {
			padding: .75rem 1rem;
		}

		textarea {
			height: 14rem;
		}
	}
}

input[type=submit] {
	background: $obblue;
	color: #fff;
	margin: 0 auto;
	padding: 1.25rem;
	width: calc( 100% - 35.125rem );
	border: none;
	margin: auto;
	display: block;
	cursor: pointer;
	transition: $tr05s;
	&:hover {
		background: darken($obblue, 5);
	}
}
.wpcf7 form .wpcf7-not-valid-tip {
	color: $salmon;
	font-size: 13px;
	margin-top: .25rem;
}
.wpcf7 form .wpcf7-response-output {
	text-align: center;
	font-size: 14px;
	background: #fff;
	padding: 0.5rem 1rem !important;
}

.inquiry_link {
	display: flex;
	align-items: center;
	margin-bottom: 18px;
	color: $dgray;

	&:before {
		content: "";
		background: #E7E7EF url(../img/arrow_next_blue.svg) no-repeat 50% 50%;
		height: 28px;
		width: 28px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 12px;
	}
}

.target {
	.wrapper {
		.target_inner {
			.target_content_full {
				width: 100%;
			}
			.target_content_half {
				width: calc( 50% - #{$unit * 4} );
				text-align: center;

				h3 {
					font-size: 22px;
					color: $obblue;
					font-weight: 500;
					line-height: 1.5;
					margin-bottom: 40px;
				}

				.graph {
					max-width: 255px;
					margin-bottom: 40px;
				}

				.text01 {
					font-size: 14px;
				}
			}
		}
	}
}

.activity_reports {
	.wrapper {
			.business_goal_disc {
				h3 {
					margin-bottom: 0;
					+ .sec_title02_days {
						margin-top: .5rem;
					}
				}
			}
			.activity_report_wrap {
			article {
				margin-bottom: 32px;

				a {
					align-items: center;
					justify-content: flex-start;
					color: #000;

					>img {
						max-width: 292px;

						+* {
							margin-left: 4rem;
						}
					}

					.activity_report_desc {
						>* {
							margin: 0 0 2rem;
						}
					}
				}
			}
		}
	}
}

.activity_report {
	.wrapper {
		.activity_report_img_30p {
			display: flex;
			margin-bottom: 48px;

			img {
				width: 30%;
			}
		}

		.sec_title02 {
			&:not(:first-child) {
				margin-top: $unit * 10;
			}
		}

		.text01 {
			+.text01 {
				margin-top: 2rem;
			}
		}
	}
}

.home {
	.kv+main {
		padding-top: 0;
	}

	.news {
		padding-top: 114px;
		padding-bottom: 72px;
	}
}

// contact
.contact {
	.content {
		ul {
			margin-bottom: $unit * 10;
		}

	}
}

// wp page navi

.cmn-pagenav {
	display: flex;
	justify-content: center;
	margin-top: 72px;

	&__item {
		line-height: 32px;
		text-align: center;
		margin: 0 6px;
		width: 2rem;
		height: 32px;

		&.is_current {
			background: $obblue;
			color: white;
		}

		&.is_prev,
		&.is_next {
			a {
				color: $obblue;
				background-repeat: no-repeat;
				background-size: 15px 15px;
				width: 20px;
				height: 32px;
				transform: translateY(-2px);

				&::before {
					content: none;
				}
			}
		}

		&.is_prev a {
			background-image: url(../img/ico/ico_arrow06.png);
			background-position: left center;
		}

		&.is_next a {
			background-image: url(../img/ico/ico_arrow07.png);
			background-position: right center;
		}

		a {
			display: block;
			position: relative;
			color: $obblue;
			transition: $tr05s;

			&::before {
				background: #7490CE;
				content: "";
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 2px;
				opacity: 0;
				transition: $tr05s;
			}

			&:hover {
				&::before {
					opacity: 1;
				}
			}
		}
	}
}

// 既存class
.photobox {
	> * {
		width: 100%;
		margin-bottom: 2rem;
	}
}

//事業目標
.title_fourten {
	display: block;
	padding: .75rem 1rem;
	font-size: 22px;
	font-weight: 600;
	text-align: center;
	border: 2px solid $obblue;
	color:  $obblue;
}

.nav_bottom.wrapper {
	background: transparent;
	padding-bottom: 0;
	.card06 {
		margin-bottom: 0;
	}
}
