.flex {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	+.sec_title02 {
		margin-top: $unit * 10;
	}

	&.flex_top {
		align-items: flex-start;
	}
}

body {
	//background: linear-gradient($paleblue,$paleblue,#ffffff);
}

p {
	line-height: 1.75;
}

a {
	transition: .3s ease;
	&:hover {
		opacity: 0.78;
	}
}

.red {
	color: $salmon;
}

.superscript {
	font-size: 0.8rem;
	vertical-align: top;
}

.subscript {
	font-size: 0.8rem;
	vertical-align: bottom;
}

.nav_title01 {
	font-size: 28px;
	margin-bottom: 72px;
	text-align: center;
	.sub {
		color: #B7B7B7;
		font-size: 12px;
		display: block;
		margin-bottom: 16px;
	}
} 

.group {
	margin-bottom: $unit * 10;
}


//-------仮クラス
.nono_decided {
	// background: linear-gradient($paleblue, $paleblue, #fff);
	// padding: 72px 0;

}

.wrapper {
	max-width: 1187px;
	width: 100%;
	margin: 0 auto;
	background: #fff;
	padding: $unit * 14;
	border-radius: 4px;
	.content {
		line-height: 1.75;
		>* {
			margin: 0 0 $unit * 15;
		}
		p, ul {
			margin-bottom: $unit * 2;
		}
	}
}

i.num {
	background: $paleblue;
	font-size: 32px;
	line-height: 3rem;
	height: 3rem;
	width: 3rem;
	display: block;
	text-align: center;
	border-radius: 50%;
	margin-right: $unit * 2;
	float: left;
	transform: translateY(-.5rem);
}

.min_wrap {
	max-width: 776px;
	margin: 0 auto;
}

.sec_title {
	margin-bottom: 42px;
	max-width: 1012px;
	margin-right: auto;
	margin-left: auto;

	span {
		color: #B7B7B7;
		font-size: 12px;
		margin-bottom: 16px;
		display: inline-block;
	}

	h3 {
		color: $obblue;
		font-size: 22px;
	}

	//---------子ページ用----------
	h1 {
		font-size: 32px;
		color: $obblue;
		font-weight: 600;

		.sec_title_sub01 {
			display: block;
			font-weight: 300;
		}

		.sec_title_sub02 {
			margin-top: 24px;
			display: block;
			color: $obblue;
			font-weight: 300;
		}
	}
}


h2.sec_title02 {
	font-size: 28px;
	font-weight: 400;
	position: relative;
	line-height: 1.5;

	&::before {
		content: url(../img/sec_title02.svg);
		position: absolute;
		top: .5em;
		left: -102px;
		transform: translateY(-50%);
	}
}


.sec_title02 {
	margin-bottom: 70px;

	h2 {
		font-size: 28px;
		margin-top: 35px;
		font-weight: 400;
		position: relative;
		line-height: 1.5;

		&::before {
			content: url(../img/sec_title02.svg);
			position: absolute;
			top: .5em;
			left: -104px;
			transform: translateY(-50%);
		}
	}

	strong {
		font-weight: 600;
		margin-right: 1rem;
		a {
			font-size: 12px;
			color: $obblue;
		}
	}

	.sec_title02_title {
		font-size: 28px;
		margin-top: 35px;
		font-weight: 400;
		display: block;
	}
}

.sec_title02_days {
	font-size: 12px;
	color: #9D9D9D;
	display: block;
	margin-top: 1rem;
}

.sec_title03 {
	h2 {
		font-size: 28px;
		margin-bottom: 72px;
		text-align: center;

		span {
			color: #B7B7B7;
			font-size: 12px;
			display: block;
			margin-bottom: 16px;
		}
	}
}

h3 {
	font-size: 22px;
	color: $obblue;
	margin-bottom: $unit * 2;
    line-height: 1.5;
    font-weight: 400;
    margin-bottom: $unit * 7;
}

h4 {
	font-weight: 400;
	display: inline-block;
    border-bottom: 2px solid #171C61;
    margin-bottom: 24px;
    padding: 0 3px 10px;
}

.text01 {
	font-size: 16px;
	color: $dgray;
	font-weight: 200;
	line-height: 1.75;
}

.text02 {
	font-size: 14px;
	line-height: 1.75;
}

.card03 {
	margin-bottom: 32px;

	&:last-child {
		margin-bottom: 0;
	}

	.card03_profimg {
		width: 226px;
		overflow: hidden;

		img {
			transition: .3s;
		}
	}

	.card03_desc {
		width: calc(100% - 226px);
		padding: 0 0 0 $unit * 10;
		display: flex;
		align-items: center;

		.card03_desc_inner {
			h4 {
				font-size: 20px;
				color: $obblue;
				margin-bottom: 16px;
				padding: 0;
				position: relative;
				display: inline-block;
				border-bottom: none;
				&::after {
					content: url(../img/arrow_next_black.svg);
					position: absolute;
					right: -24px;
					top: 45%;
					transform: translateY(-50%);
					transition: .3s;
				}
			}

			p {
				color: $dgray;
			}
		}
	}

	&:hover {
		.card03_profimg {
			img {
				transform: scale(1.1);
				transition: .3s;
			}
		}

		.card03_desc {
			.card03_desc_inner {
				h4 {
					&::after {
						right: -29px;
						transition: .3s;
					}
				}
			}
		}
	}
}

.card05 {
	display: flex;
	width: 100%;
	justify-content: center;
	margin-bottom: $unit * 5;
	flex-wrap: wrap;

	> li,
	.card_content {
		width: calc(33% - #{$unit * 4 });
		margin: 0 $unit * 2 $unit * 4;

		.card_img {
			overflow: hidden;

			img {
				width: 100%;
				height: calc(232px / 1.5);
				object-fit: cover;
				transition: .3s;
			}
		}

		> a,
		.card_txt {
			font-size: 14px;
			letter-spacing: 0.05rem;
			height: 58px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: $dgray;
			background: $llgray;
			width: 100%;
			position: relative;
			padding: 0 $unit * 4 0 $unit * 2;

			&::after {
				content: url(../img/arrow_next_black.svg);
				position: absolute;
				right: 21px;
				transition: .3s;
			}
		}

		&:hover {
			.card_img {
				img {
					transform: scale(1.1);
					transition: .3s;
				}
			}

			> a, 
			.card_txt {
				&::after {
					right: 16px;
					transition: .3s;
				}
			}
		}
	}
}

.card06 {
	@extend .card05;
}

.button03 {
	color: #fff;
	border-bottom: 2px solid #fff;
	padding: 24px 64px;
	position: relative;
	opacity: 1;
	transition: .3s;

	&::after {
		content: url(../img/icon_new_tab.svg);
		position: absolute;
		right: 32px;
		top: 50%;
		transform: translateY(-50%);
		transition: .3s;
	}

	&:hover {
		opacity: 0.7;
		transition: .3s;

		&::after {
			right: 27px;
			transition: .3s;
		}
	}
}

.button05 {
	background: $obblue;
	color: #fff;
	font-size: 12px;
	padding: 8px 24px 8px 16px;
	position: relative;
	display: inline-block;

	&::after {
		content: url(../img/arrow_next.svg);
		position: absolute;
		right: 12px;
		top: 50%;
		transform: translateY(-50%);
		transition: .3s;
	}

	&:hover {
		&::after {
			right: 5px;
			transition: .3s;
		}
	}
}

.business_goal_sec01 {
	margin-bottom: 148px;

	.business_goal_img {
		// max-width: 400px;
		width: calc( 50% - #{$unit * 8} );
	}

	.business_goal_disc {
		width: 50%;
		p {
			margin-bottom: 2rem;
		}
	}
}

.one-col {
	@include flex;

	&.w75p {
		margin: 0 12.5%;
	}

	&.w50p {
		margin: 0 25%;
	}

	+.sec_title02 {
		margin-top: $unit * 10;
	}
}

.filter {
	width: 100%;
	background: $llgray;
	justify-content: start;
	width: calc(100% + 198px);
	margin-left: -99px;
	margin-bottom: 36px;

	li {
		position: relative;
		> * {
			display: block;
			padding: 21px 36px;
			color: #000;
			font-size: $unit * 2;

			&:hover {
				cursor: pointer;
			}
		}

		.filter_on_toggle {
			&::after {
				content: url(../img/arrow_next_black.svg);
				position: absolute;
				right: $unit * 2;
				transform: rotateZ(90deg);
				transition: .3s;
			}

			&.filter_on_toggle_cate {
				min-width: 180px;
				@include mq(tb) {
					min-width: auto;
				}
			}

			&.filter_on_toggle_year {
				min-width: 130px;

				@include mq(tb) {
					min-width: auto;
				}
			}

			&.filter_open {
				&::after {
					transform: rotateZ(270deg);
					transition: .3s;
				}
			}
		}

		.filter_on_toggle_wrap {
			position: absolute;	
			background: #fff;
			display: none;
			width: 100%;
			padding: $unit * 2 0;
			box-shadow: 0 0 10px rgb(23 28 97 / 20%);
			z-index: 5;

			li {
				a {
					min-width: 10rem;
					padding: $unit * 2 $unit * 4;
				}
			}
			.children {
				padding: 0 !important;
				text-indent: 1rem;
			}
		}
	}
}

.youtubebox {
	position: relative;
	width: 100%;
	padding-top: 56.25%;
	iframe {
		position: absolute;
		top: 0;
		right: 0;
		width: 100% !important;
		height: 100% !important;
	}
}