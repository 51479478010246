// tb =  tablet, 767px以下、 sp = smartphone, 599px以下

.onlysp {
	@include mq(pcmin) {
		display: none;
	}
}

.onlypc {
	@include mq(tb) {
		display: none;
	}
}


body.noscroll {
	overflow: hidden;
}

.nav_sp {
	position: fixed;
	z-index: 5;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background: #fff;
	padding-top: 100px;
	display: none;
	overflow: hidden;
	overflow-y: scroll;

	ul {
		li {
			a {
				padding: 25px 40px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				color: $obblue;
				border-bottom: 1px solid #f2f2f2;
				font-weight: 400;
			}

			&.header_nav_li {
				.nav_toggle {
					height: $unit * 4;
					width: $unit * 4;
					position: relative;

					span {
						height: 1px;
						width: 50%;
						background: $obblue;
						display: block;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						right: 0;

						&.nav_toggleline01 {}

						&.nav_toggleline02 {
							transform: rotate(90deg);
							transition: .3s;
						}
					}

					&.nav_open {
						span {
							&.nav_toggleline02 {
								transform: none;
								transition: .3s;
							}
						}
					}

				}
			}


			.header_nav_big {
				display: none;
			}

		}
	}

	.plus_arrow,
	.header_nav_big_link {
		a {
			&:after {
				content: "";
				background: url(../img/arrow_next_blue.svg) no-repeat 50% 50%;
				height: $unit * 2;
				width: $unit * 2;
			}
		}
	}

}

.menu_bar {
	position: fixed;
	right: 20px;
	height: 16px;
	width: 24px;
	flex-wrap: wrap;
	justify-content: space-between;
	top: 50%;
	transform: translateY(-50%);
	display: none;

	.menu_line {
		background: #000;
		height: 1px;
		width: 100%;
		position: absolute;
		transition: .3s;

		&.line_top {
			top: 0;
		}

		&.line_mid {
			top: 50%;
			transform: translateY(-50%);
		}

		&.line_bot {
			bottom: 0;
		}
	}

	&.menu_bar_open {
		.menu_line {
			transition: .3s;

			&.line_top {
				top: 8px;
				transform: rotate(45deg);
			}

			&.line_mid {
				opacity: 0;
			}

			&.line_bot {
				transform: rotate(-45deg);
				bottom: 7px;
			}
		}
	}

	&.on_shadow {
		box-shadow: 0 0 15px rgb(0 0 0 / 8%);
	}
}

//-----------header-------------
@media (max-width: 1186px) {
	header {
		width: calc(100% - 28px);
	}

	.wrapper {
		width: calc(100% - 30px);
	}
	.breadcrumb_trail {
		ul {
			padding-left: 14px;
			padding-right: 14px;
		}
	}
}

@media (max-width: 950px) {
	header {
		border-radius: 10px;

		.header_nav {
			display: none;
		}

		.menu_bar {
			display: flex;
		}
	}
	.list_of_bases {
		.wrapper {
			.content {
				.base_links {
					display: block;
					margin-bottom: 72px;

					.bases_map {
						max-width: 100%;
					}

					.base_links_all {
						width: 100%;
						padding: 0;
					}
				}

				.base_desc_wrap {
					display: block;

					.bases_desc {
						width: 100%;
					}

					.base_img {
						width: 100%;
					}
				}
			}
		}
	}
}

//-----------header-------------


@media (max-width: 1056px) {
	.sec_title {
		margin-left: $unit * 2;
		margin-right: $unit * 2;
	}
	//-----------footer-------------
	footer {
		.wrapper {
			display: block;
			padding: 48px 15px;

			.footer_wrap_left {
				text-align: center;
				margin: 0 auto;
				margin-bottom: $unit * 5;
			}

			.footer_wrap_right {
				text-align: left;
				width: auto;

				.footer_wrap_right_inner {
					margin-left: -1rem;
				}
			}
		}
	}
	main > section {
		padding-right: 14px;
		padding-left: 14px;
	}
	.recruit {
		padding-left: 0;
		padding-right: 0;
		.sec_title {
			padding-left: 14px;
		}
	}
}



@include mq(tb) {


p, ul li, dl dt, dl dd {
	font-size: 14px;
	line-height: 1.75;
}


	.card05,
	.card06 {

		>li,
		.card_content {
			width: 100%;
			margin: 0 0 $unit * 2;
		}
	}

	.card05 {
		.card_content {
			width: 100%;
			margin: 0 0px 28px;

			.card_txt {
				p {
					font-size: 12px;
				}
			}
		}
	}

	.menu_bar {
		display: block;
	}

	header {
		top: 15px;
		height: 60px;

		.header_nav_logo {
			max-width: 108px;
		}

		.header_nav {
			display: none;
		}

		.menu_bar {
			display: flex;
		}
	}

	main>section {
		padding: $unit * 5 $unit * 2;

		.wrapper {
			padding: $unit * 5 $unit * 3 $unit * 10;
			width: calc(100% - 30px);

			.sec_title02 {
				&:before {
					left: -26px;
					width: $unit * 3;
					overflow: hidden;
				}

				.sec_title02_title {
					margin-top: 18px;
				}
			}
		}
	}

	.news_detail {

		.wrapper {
			.news_detail_nav {
				margin-top: 72px;
			}
		}
	}

	.about {
		padding-bottom: 72px;

		.about_contents {
			.button02_ul {
				display: block;

				.button02 {
					margin: 0 auto 32px;
				}
			}
		}
	}

	.recruit {
		padding: 32px 0 0;

		.sec_title {
			padding: 0 15px;
		}

		.recruit_content {
			.recruit_content_desc {
				width: 100%;
				order: 1;
				padding: 48px 0 72px;
			}

			.recruit_content_img {
				width: 100%;
				order: 2;
			}
		}
	}

	.list_of_bases {
		.wrapper {

			.flex {

				.bases {
					width: 100%;
					flex-wrap: wrap;

					.bases_inner {
						margin-bottom: 32px;
					}

					.base_img {
						width: 100%;
					}
				}
			}
		}
	}

	.resident_info {
		.container {
			padding: 1rem;
		}
	}

	// added by ODA
	.text01 {
		font-size: $unit * 2;
	}
	.wrapper {
		.content {
			>* {
				margin-bottom: $unit * 8;
			}
		}
	}

	footer {
		.wrapper {
			.footer_wrap_right {
				.footer_wrap_right_sns {
					justify-content: center;
					margin-bottom: $unit * 5;
				}

				small {
					text-align: center;
					line-height: 1.5;
				}
			}
		}
	}

	main {
		padding: $unit * 10 0;
	}

	.sec_title {
		h1 {
			font-size: $unit * 3;

			.sec_title_sub01 {
				font-size: 11px;
			}
		}
	}

	h2.sec_title02, .sec_title02 h2 {
		font-size: $unit * 3;
		margin-bottom: $unit * 5;
	}
	div.sec_title02 {
		margin-bottom: $unit * 5;
	}

	h3 {
		font-size: $unit * 2.75;
		margin-bottom: $unit * 3;
	}

	.nav_title01 {
		margin-bottom: $unit * 5;
	}

	.sec_title {
		margin-bottom: $unit * 5;
		padding-left: 15px;

		span {
			margin-bottom: $unit;
		}
	}

	.home {
		.kv {
			.kv_guidance {
				width: calc(100% - #{$unit * 10});
				left: $unit * 5;
				bottom: $unit * 5;

				h1 {
					font-size: $unit * 4;
				}
			}
		}

		.recruit {
			.recruit_content {
				.recruit_content_desc {
					.recruit_content_desc_inner {
						span {
							margin-bottom: $unit * 3;
						}
					}
				}
			}
		}

		.about {
			.about_contents {
				.about_fir {
					img {
						width: $unit * 7;
						margin-right: $unit;
					}
				}

				h1 {
					font-size: $unit * 4;
				}

				.button02_ul {
					.button02 {
						margin-bottom: $unit * 3;
						max-width: none;
					}
				}
			}
		}

		.messages {
			padding: $unit * 10 $unit * 2 0;

			.min_wrap {
				h2 {
					margin-bottom: $unit * 6;
				}

				.message_cards {
					.message_card {
						margin-bottom: $unit * 6;
						max-width: none;

						&.message_card_wide {
							display: block;

							.message_card_img {
								max-width: none;
								img {
									width: 100%;
								}
							}

							.flex {
								height: $unit * 8;
							}
						}
					}
				}
			}
		}
	}

	.business_goal_sec01 {
		.business_goal_img,
		.business_goal_disc {
			width: 100%;
			&:not(:last-child) {
				margin-bottom: $unit * 6;
			}
		}
	}

	.target {
		.wrapper {
			.target_inner {
				.target_content_half {
					width: 100%;
					&:not(:last-child) {
						margin-bottom: $unit * 6;
					}
					h3 {
						font-size: $unit * 2.5;
					}
				}
			}
		}
	}

	.business_goal_sec01 {
		margin-bottom: 0;
	}

	.overview {
		.wrapper {
			dl, .overview_2nd {
				display: block;
				dt, dd {
					width: 100% !important;
					font-size: $unit * 2;
				}
				dt {
					margin-bottom: $unit * 2;
					font-weight: 400;
				}
				dd {
					display: block !important;
					&:not(:last-child) {
						margin-bottom: $unit * 6;
					}
				}
			}
			.overview_2nd  {
				dd {
					text-align: center;
				}
			}	
		}
	}
	.chronology {
		.chronology_wrap {
			display: block;
			.flex {
				> * {
					width: 100% !important;
				}
				span, .tpix, .tenpo_tenkai {
					display: none;
				}
				.day, .text01 {
					font-size: $unit * 2.25 !important;
				}
				.day {
					margin-bottom: $unit * 1;
				}
			}
		}
	}
	.board_member_introduction_content {
		display: block !important;
		.board_member_introduction_img, .board_member_introduction_desc {
			width: 100% !important;
			padding: 0 !important;
		}
	}
	.filter {
		width: 100%;
		margin: 0;
		> * {
			width:100%;
			&:first-child {
				display: none;
			}
		}
	}

	.news_detail_nav {
		flex-wrap: wrap;
		font-size: $unit * 2;
		> * {
			width: 100% !important;
			margin-bottom: $unit * 3;
		}
		.back {
			order: 3;
		}
	}

	form {
		padding: $unit * 3;
		dl {
			display: block;
			>* {
				width: 100% !important;
			}
			dt{
				margin-bottom: $unit;
			}
			dd {
				margin-bottom: $unit * 3;
			}
		}
		input[type=submit] {
			width: 100%;
			max-width: 300px;
		}
	}
	.senior_employee {
		.wrapper {
			.senior_employee_kv {
				width: calc(100% + 42px);
				margin-left: -21px;
				max-height: 200px;
			}

			.senior_employee_wrap {
				flex-wrap: wrap;
				.senior_employee_title {
					width: 100%;
				}
				.senior_employee_inner {
					width: 100%;
				}
			}
		}
	}
}

@include mq(sp) {

	.wrapper {
		width: 100% !important;
	}

	.senior_list {
		padding: 48px 0;
	}

	.sec_title02 {
		margin-bottom: 32px;
		h2 {
			&::before {
				width: 21px;
				overflow: hidden;
				left: -25px;
			}
		}
	}

	.card03 {
		.card03_profimg {
			width: 100%;
			img {
				width: 100%;
			}
		}

		.card03_desc {
			width: 100%;
			padding: 24px 16px 8px;
		}
	}

	.activity_report {
		.wrapper {
			.activity_report_img_30p {
				img {
					width: 100%;
					&:not(:last-child) {
						margin-bottom: $unit * 3; // 21px
					}
				}		
			}		
		}
	}
}