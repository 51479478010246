@charset "UTF-8";
/*--------------------

	underline

--------------------*/
/*--------------------
	arrows
--------------------*/
/*1.フェードインアニメーションの指定*/
.scrollanime {
  opacity: 0;
}

/*一瞬表示されるのを防ぐ*/
.fadeInDown {
  animation-name: fadeInDown;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
/*2.上下の動きを指定*/
.updown {
  transform: translateY(-30px);
}

.downup {
  transform: translateY(30px);
}

/*3.左右の動きを指定*/
.sect02 {
  overflow: hidden;
}

/*横スクロールバーを隠す*/
.slide-right {
  transform: translateX(200px);
}

.slide-left {
  transform: translateX(-200px);
}

/*--------------------
	arrows
--------------------*/
/*===== BASE =====*/
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
/* HTML5 display-role reset for older browsers */
* {
  object-fit: contain;
}

html {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  letter-spacing: 0.1rem;
  transition: 0.3s;
  font-weight: 200;
}

body {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  line-height: 1;
  font-family: "ヒラギノ角ゴシック";
  color: #2E2E2E;
}

div {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

span {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

applet {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

object {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

iframe {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

h1 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 32px;
  font: inherit;
  vertical-align: baseline;
  color: #fff;
  line-height: 1.25;
}

h2 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

h3 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

h4 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

h5 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

h6 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

p {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

blockquote {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  quotes: none;
}
blockquote:before {
  content: "";
  content: none;
}
blockquote:after {
  content: "";
  content: none;
}

pre {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-decoration: none;
}

abbr {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

acronym {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

address {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

big {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

cite {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

code {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

del {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

dfn {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

em {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

img {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

ins {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

kbd {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

q {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  quotes: none;
}
q:before {
  content: "";
  content: none;
}
q:after {
  content: "";
  content: none;
}

s {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

samp {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

small {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

strike {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

strong {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

sub {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

sup {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

tt {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

var {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

b {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

u {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

i {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

center {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

dl {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

dt {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

dd {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

ol {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  list-style: none;
}

ul {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  list-style: none;
}

li {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  list-style: none;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

form {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

label {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

legend {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

table {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

caption {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

tbody {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

tfoot {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

thead {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

tr {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

th {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

td {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block;
}

aside {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block;
}

canvas {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

details {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block;
}

embed {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

figure {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block;
}

figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block;
}

footer {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block;
}

header {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block;
}

hgroup {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block;
}

menu {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block;
}

nav {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block;
}

output {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

ruby {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

section {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block;
}

summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

time {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

mark {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

audio {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.clear {
  clear: both;
}

.clearfix {
  *zoom: 1;
}
.clearfix:before {
  content: " ";
  display: table;
}
.clearfix:after {
  content: " ";
  display: table;
  clear: both;
}

.flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.flex + .sec_title02 {
  margin-top: 70px;
}
.flex.flex_top {
  align-items: flex-start;
}

p {
  line-height: 1.75;
}

a {
  transition: 0.3s ease;
}
a:hover {
  opacity: 0.78;
}

.red {
  color: #E35A5A;
}

.superscript {
  font-size: 0.8rem;
  vertical-align: top;
}

.subscript {
  font-size: 0.8rem;
  vertical-align: bottom;
}

.nav_title01 {
  font-size: 28px;
  margin-bottom: 72px;
  text-align: center;
}
.nav_title01 .sub {
  color: #B7B7B7;
  font-size: 12px;
  display: block;
  margin-bottom: 16px;
}

.group {
  margin-bottom: 70px;
}

.wrapper {
  max-width: 1187px;
  width: 100%;
  margin: 0 auto;
  background: #fff;
  padding: 98px;
  border-radius: 4px;
}
.wrapper .content {
  line-height: 1.75;
}
.wrapper .content > * {
  margin: 0 0 105px;
}
.wrapper .content p, .wrapper .content ul {
  margin-bottom: 14px;
}

i.num {
  background: #DFE9EF;
  font-size: 32px;
  line-height: 3rem;
  height: 3rem;
  width: 3rem;
  display: block;
  text-align: center;
  border-radius: 50%;
  margin-right: 14px;
  float: left;
  transform: translateY(-0.5rem);
}

.min_wrap {
  max-width: 776px;
  margin: 0 auto;
}

.sec_title {
  margin-bottom: 42px;
  max-width: 1012px;
  margin-right: auto;
  margin-left: auto;
}
.sec_title span {
  color: #B7B7B7;
  font-size: 12px;
  margin-bottom: 16px;
  display: inline-block;
}
.sec_title h3 {
  color: #171C61;
  font-size: 22px;
}
.sec_title h1 {
  font-size: 32px;
  color: #171C61;
  font-weight: 600;
}
.sec_title h1 .sec_title_sub01 {
  display: block;
  font-weight: 300;
}
.sec_title h1 .sec_title_sub02 {
  margin-top: 24px;
  display: block;
  color: #171C61;
  font-weight: 300;
}

h2.sec_title02 {
  font-size: 28px;
  font-weight: 400;
  position: relative;
  line-height: 1.5;
}
h2.sec_title02::before {
  content: url(../img/sec_title02.svg);
  position: absolute;
  top: 0.5em;
  left: -102px;
  transform: translateY(-50%);
}

.sec_title02 {
  margin-bottom: 70px;
}
.sec_title02 h2 {
  font-size: 28px;
  margin-top: 35px;
  font-weight: 400;
  position: relative;
  line-height: 1.5;
}
.sec_title02 h2::before {
  content: url(../img/sec_title02.svg);
  position: absolute;
  top: 0.5em;
  left: -104px;
  transform: translateY(-50%);
}
.sec_title02 strong {
  font-weight: 600;
  margin-right: 1rem;
}
.sec_title02 strong a {
  font-size: 12px;
  color: #171C61;
}
.sec_title02 .sec_title02_title {
  font-size: 28px;
  margin-top: 35px;
  font-weight: 400;
  display: block;
}

.sec_title02_days {
  font-size: 12px;
  color: #9D9D9D;
  display: block;
  margin-top: 1rem;
}

.sec_title03 h2 {
  font-size: 28px;
  margin-bottom: 72px;
  text-align: center;
}
.sec_title03 h2 span {
  color: #B7B7B7;
  font-size: 12px;
  display: block;
  margin-bottom: 16px;
}

h3 {
  font-size: 22px;
  color: #171C61;
  margin-bottom: 14px;
  line-height: 1.5;
  font-weight: 400;
  margin-bottom: 49px;
}

h4 {
  font-weight: 400;
  display: inline-block;
  border-bottom: 2px solid #171C61;
  margin-bottom: 24px;
  padding: 0 3px 10px;
}

.text01 {
  font-size: 16px;
  color: #2E2E2E;
  font-weight: 200;
  line-height: 1.75;
}

.text02 {
  font-size: 14px;
  line-height: 1.75;
}

.card03 {
  margin-bottom: 32px;
}
.card03:last-child {
  margin-bottom: 0;
}
.card03 .card03_profimg {
  width: 226px;
  overflow: hidden;
}
.card03 .card03_profimg img {
  transition: 0.3s;
}
.card03 .card03_desc {
  width: calc(100% - 226px);
  padding: 0 0 0 70px;
  display: flex;
  align-items: center;
}
.card03 .card03_desc .card03_desc_inner h4 {
  font-size: 20px;
  color: #171C61;
  margin-bottom: 16px;
  padding: 0;
  position: relative;
  display: inline-block;
  border-bottom: none;
}
.card03 .card03_desc .card03_desc_inner h4::after {
  content: url(../img/arrow_next_black.svg);
  position: absolute;
  right: -24px;
  top: 45%;
  transform: translateY(-50%);
  transition: 0.3s;
}
.card03 .card03_desc .card03_desc_inner p {
  color: #2E2E2E;
}
.card03:hover .card03_profimg img {
  transform: scale(1.1);
  transition: 0.3s;
}
.card03:hover .card03_desc .card03_desc_inner h4::after {
  right: -29px;
  transition: 0.3s;
}

.card05, .card06 {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 35px;
  flex-wrap: wrap;
}
.card05 > li, .card06 > li,
.card05 .card_content,
.card06 .card_content {
  width: calc(33% - 28px);
  margin: 0 14px 28px;
}
.card05 > li .card_img, .card06 > li .card_img,
.card05 .card_content .card_img,
.card06 .card_content .card_img {
  overflow: hidden;
}
.card05 > li .card_img img, .card06 > li .card_img img,
.card05 .card_content .card_img img,
.card06 .card_content .card_img img {
  width: 100%;
  height: calc(232px / 1.5);
  object-fit: cover;
  transition: 0.3s;
}
.card05 > li > a, .card06 > li > a,
.card05 > li .card_txt,
.card06 > li .card_txt,
.card05 .card_content > a,
.card06 .card_content > a,
.card05 .card_content .card_txt,
.card06 .card_content .card_txt {
  font-size: 14px;
  letter-spacing: 0.05rem;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2E2E2E;
  background: #eaeaea;
  width: 100%;
  position: relative;
  padding: 0 28px 0 14px;
}
.card05 > li > a::after, .card06 > li > a::after,
.card05 > li .card_txt::after,
.card06 > li .card_txt::after,
.card05 .card_content > a::after,
.card06 .card_content > a::after,
.card05 .card_content .card_txt::after,
.card06 .card_content .card_txt::after {
  content: url(../img/arrow_next_black.svg);
  position: absolute;
  right: 21px;
  transition: 0.3s;
}
.card05 > li:hover .card_img img, .card06 > li:hover .card_img img,
.card05 .card_content:hover .card_img img,
.card06 .card_content:hover .card_img img {
  transform: scale(1.1);
  transition: 0.3s;
}
.card05 > li:hover > a::after, .card06 > li:hover > a::after,
.card05 > li:hover .card_txt::after,
.card06 > li:hover .card_txt::after,
.card05 .card_content:hover > a::after,
.card06 .card_content:hover > a::after,
.card05 .card_content:hover .card_txt::after,
.card06 .card_content:hover .card_txt::after {
  right: 16px;
  transition: 0.3s;
}

.button03 {
  color: #fff;
  border-bottom: 2px solid #fff;
  padding: 24px 64px;
  position: relative;
  opacity: 1;
  transition: 0.3s;
}
.button03::after {
  content: url(../img/icon_new_tab.svg);
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s;
}
.button03:hover {
  opacity: 0.7;
  transition: 0.3s;
}
.button03:hover::after {
  right: 27px;
  transition: 0.3s;
}

.button05 {
  background: #171C61;
  color: #fff;
  font-size: 12px;
  padding: 8px 24px 8px 16px;
  position: relative;
  display: inline-block;
}
.button05::after {
  content: url(../img/arrow_next.svg);
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s;
}
.button05:hover::after {
  right: 5px;
  transition: 0.3s;
}

.business_goal_sec01 {
  margin-bottom: 148px;
}
.business_goal_sec01 .business_goal_img {
  width: calc( 50% - 56px );
}
.business_goal_sec01 .business_goal_disc {
  width: 50%;
}
.business_goal_sec01 .business_goal_disc p {
  margin-bottom: 2rem;
}

.one-col {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
@media screen and (max-width: 599px) {
  .one-col {
    display: block;
  }
}
.one-col.w75p {
  margin: 0 12.5%;
}
.one-col.w50p {
  margin: 0 25%;
}
.one-col + .sec_title02 {
  margin-top: 70px;
}

.filter {
  width: 100%;
  background: #eaeaea;
  justify-content: start;
  width: calc(100% + 198px);
  margin-left: -99px;
  margin-bottom: 36px;
}
.filter li {
  position: relative;
}
.filter li > * {
  display: block;
  padding: 21px 36px;
  color: #000;
  font-size: 14px;
}
.filter li > *:hover {
  cursor: pointer;
}
.filter li .filter_on_toggle::after {
  content: url(../img/arrow_next_black.svg);
  position: absolute;
  right: 14px;
  transform: rotateZ(90deg);
  transition: 0.3s;
}
.filter li .filter_on_toggle.filter_on_toggle_cate {
  min-width: 180px;
}
@media screen and (max-width: 767px) {
  .filter li .filter_on_toggle.filter_on_toggle_cate {
    min-width: auto;
  }
}
.filter li .filter_on_toggle.filter_on_toggle_year {
  min-width: 130px;
}
@media screen and (max-width: 767px) {
  .filter li .filter_on_toggle.filter_on_toggle_year {
    min-width: auto;
  }
}
.filter li .filter_on_toggle.filter_open::after {
  transform: rotateZ(270deg);
  transition: 0.3s;
}
.filter li .filter_on_toggle_wrap {
  position: absolute;
  background: #fff;
  display: none;
  width: 100%;
  padding: 14px 0;
  box-shadow: 0 0 10px rgba(23, 28, 97, 0.2);
  z-index: 5;
}
.filter li .filter_on_toggle_wrap li a {
  min-width: 10rem;
  padding: 14px 28px;
}
.filter li .filter_on_toggle_wrap .children {
  padding: 0 !important;
  text-indent: 1rem;
}

.youtubebox {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}
.youtubebox iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
}

body {
  overflow-x: hidden;
}
body:not(.home) {
  background: linear-gradient(#DFE9EF, #DFE9EF, #ffffff);
}
body:not(.home) > section:not(:last-child) {
  padding: 0 0 70px;
}

header {
  max-width: 1186px;
  height: 73px;
  margin: 0 auto;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24.5px;
  border-radius: 4px;
  position: fixed;
  width: 100%;
  top: 31px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  box-shadow: 0 0 10px rgba(23, 28, 97, 0.2);
}
header .header_nav {
  display: flex;
  justify-content: flex-end;
}
header .header_nav i.underline {
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: #171C61;
  transition: all 0.5s ease;
  z-index: -1;
}
header .header_nav .header_nav_li {
  display: block;
}
header .header_nav .header_nav_li .header_nav_big {
  display: none;
  position: absolute;
  top: 73px;
  left: 3.5px;
  width: calc( 100% - 7px );
}
header .header_nav .header_nav_li .header_nav_big .wrapper {
  background: rgba(23, 28, 97, 0.87);
  padding: 52.5px 140px 52.5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
header .header_nav .header_nav_li .header_nav_big .wrapper h3 {
  color: #fff;
  font-size: 28px;
}
header .header_nav .header_nav_li .header_nav_big .wrapper h3 a {
  color: #fff;
}
header .header_nav .header_nav_li .header_nav_big .wrapper h3 span {
  color: #B7B7B7;
  font-size: 12px;
  display: block;
  margin-bottom: 12px;
}
header .header_nav .header_nav_li .header_nav_big .wrapper h3 span::after {
  content: url(../img/arrow_next.svg);
  position: relative;
  right: -12px;
  top: -1px;
}
header .header_nav .header_nav_li .header_nav_big .wrapper .header_nav_big_links {
  max-width: 635px;
}
header .header_nav .header_nav_li .header_nav_big .wrapper .header_nav_big_links .header_nav_big_link {
  width: calc( 50% - 28px );
  margin-bottom: 21px;
}
header .header_nav .header_nav_li .header_nav_big .wrapper .header_nav_big_links .header_nav_big_link:last-child {
  margin-bottom: 0;
}
header .header_nav .header_nav_li .header_nav_big .wrapper .header_nav_big_links .header_nav_big_link a {
  color: #fff;
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #fff;
  position: relative;
  font-size: 14px;
  letter-spacing: 0;
}
header .header_nav .header_nav_li .header_nav_big .wrapper .header_nav_big_links .header_nav_big_link a::after {
  content: url(../img/arrow_next.svg);
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s;
}
header .header_nav .header_nav_li .header_nav_big .wrapper .header_nav_big_links .header_nav_big_link a:hover::after {
  right: 0;
  transition: 0.3s;
}
header .header_nav .header_nav_li .header_nav_big .wrapper .header_nav_big_links .header_nav_big_link.is_small a {
  font-size: 13px;
}
header .header_nav .header_nav_li > a {
  font-size: 14px;
  color: #171C61;
  font-weight: 600;
  height: 73px;
  padding: 0 21px;
  line-height: 73px;
  display: block;
  border-bottom: 2px solid transparent;
  transition: all 0.5s ease;
}
.kv {
  overflow: hidden;
  background: url(../img/kv.jpg);
  height: 100vh;
  width: 100%;
  position: relative;
  transition: 0.3s;
}
.kv::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.kv video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  background: #000;
  background-size: cover;
}
.kv .kv_guidance {
  position: absolute;
  bottom: 60px;
  left: 12%;
  z-index: 1;
}
.kv .kv_guidance .kv_guidance_title {
  background: #fff;
  color: #171C61;
  width: auto;
  height: auto;
  display: inline-block;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
}
.kv .kv_guidance .kv_guidance_title a {
  display: inline-block;
  line-height: 1;
  padding: 1rem 2rem 1rem 1rem;
  color: #171C61;
  position: relative;
}
.kv .kv_guidance .kv_guidance_title a::after {
  content: url(../img/arrow_next_blue.svg);
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s;
}
.kv .kv_guidance .kv_guidance_title a:hover::after {
  right: calc(1rem - 5px);
  transition: 0.3s;
}
.kv .kv_guidance h1 {
  font-size: 32px;
  font-weight: 600px;
  margin-bottom: 28px;
}
.kv.nav_open {
  transform: translate3d(-100%, 0, 0);
  transition: 0.3s;
}

main {
  padding: 140px 0;
  min-width: 1183px;
}
main.nav_open {
  transform: translate3d(-100%, 0, 0);
  transition: 0.3s;
}
@media (max-width: 1183px) {
  main {
    min-width: auto;
  }
}

.about {
  background: url(../img/about_bg.jpg);
  background-size: cover;
  padding-top: 74px;
  padding-bottom: 144px;
  position: relative;
}
.about::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(23, 28, 97, 0.9);
}
.about .about_contents {
  position: relative;
  z-index: 1;
  max-width: 1035px;
  margin: 0 auto;
}
.about .about_contents .about_fir {
  color: #fff;
  margin-bottom: 48px;
}
.about .about_contents .about_fir img {
  margin-right: 15px;
}
.about .about_contents h1 {
  text-align: center;
  font-size: 32px;
  margin-bottom: 46px;
  line-height: 1.5;
}
.about .about_contents .button02_ul {
  max-width: 667px;
  margin: 0 auto;
}
.about .about_contents .button02_ul .button02 {
  width: 100%;
  max-width: 185px;
  border-bottom: 2px solid #fff;
}
.about .about_contents .button02_ul .button02 a {
  color: #fff;
  font-weight: 300;
  display: block;
  text-align: center;
  position: relative;
  padding: 20px;
}
.about .about_contents .button02_ul .button02 a::after {
  content: url(../img/arrow_next.svg);
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s;
}
.about .about_contents .button02_ul .button02 a:hover::after {
  right: -5px;
  transition: 0.3s;
}

.news .sec_title {
  max-width: 1012px;
  margin-right: auto;
  margin-left: auto;
}
.news .news_wrap {
  padding: 0 8px;
  max-width: 1012px;
  margin: 0 auto;
  margin-bottom: 32px;
}
.news .news_wrap .news_content {
  padding: 32px 0;
  border-bottom: 1px solid #eaeaea;
}
.news .news_wrap .news_content .news_info {
  color: #939393;
  font-size: 11px;
  margin-bottom: 21px;
}
.news .news_wrap .news_content .news_info a {
  color: #171C61;
  font-size: 12px;
}
.news .news_wrap .news_content .text01 a {
  color: #2E2E2E;
}
.news .news_wrap .news_content:last-child {
  border-bottom: none;
}
.news .btm_na_btn {
  max-width: 1012px;
  margin: 0 auto;
  text-align: right;
}
.news .btm_na_btn .button02 {
  color: #2E2E2E;
  font-size: 14px;
}
.news .btm_na_btn .button02::after {
  content: url(../img/arrow_next_blue.svg);
  height: 28px;
  width: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #E7E7EF;
  margin-left: 32px;
}

.recruit {
  padding-top: 32px;
}
.recruit .recruit_content .recruit_content_desc {
  width: 58%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.recruit .recruit_content .recruit_content_desc.recruit_content_desc01 {
  background: url(../img/recruit_bg01.jpg) no-repeat 50% 50%;
}
.recruit .recruit_content .recruit_content_desc.recruit_content_desc01::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(43, 52, 173, 0.9);
}
.recruit .recruit_content .recruit_content_desc.recruit_content_desc02 {
  background: url(../img/recruit_bg02.jpg) no-repeat 50% 50%;
}
.recruit .recruit_content .recruit_content_desc.recruit_content_desc02::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(6, 92, 68, 0.9);
}
.recruit .recruit_content .recruit_content_desc .recruit_content_desc_inner {
  position: relative;
  z-index: 1;
  color: #fff;
  text-align: center;
}
.recruit .recruit_content .recruit_content_desc .recruit_content_desc_inner span {
  color: #B7B7B7;
  font-size: 12px;
  margin-bottom: 32px;
  display: inline-block;
}
.recruit .recruit_content .recruit_content_desc .recruit_content_desc_inner h5 {
  font-size: 20px;
  line-height: 1.75;
  margin-bottom: 24px;
}
.recruit .recruit_content .recruit_content_img {
  width: 42%;
}

.about {
  background: url(../img/about_bg.jpg);
  background-size: cover;
  padding-top: 74px;
  padding-bottom: 144px;
  position: relative;
}
.about::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(23, 28, 97, 0.9);
}
.about .about_contents {
  position: relative;
  z-index: 1;
  max-width: 1035px;
  margin: 0 auto;
}
.about .about_contents .about_fir {
  color: #fff;
  margin-bottom: 48px;
}
.about .about_contents .about_fir img {
  margin-right: 15px;
}
.about .about_contents h1 {
  text-align: center;
  font-size: 32px;
  margin-bottom: 46px;
  line-height: 1.5;
}
.about .about_contents .button02_ul {
  max-width: 667px;
  margin: 0 auto;
}
.about .about_contents .button02_ul .button02 {
  width: 100%;
  max-width: 185px;
  border-bottom: 2px solid #fff;
}
.about .about_contents .button02_ul .button02 a {
  color: #fff;
  font-weight: 300;
  display: block;
  text-align: center;
  position: relative;
  padding: 20px;
}
.about .about_contents .button02_ul .button02 a::after {
  content: url(../img/arrow_next.svg);
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s;
}
.about .about_contents .button02_ul .button02 a:hover::after {
  right: -5px;
  transition: 0.3s;
}

.messages {
  padding-top: 108px;
  padding-bottom: 80px;
  background: #fff;
}
.messages .min_wrap h2 {
  font-size: 28px;
  margin-bottom: 72px;
  text-align: center;
}
.messages .min_wrap h2 span {
  color: #B7B7B7;
  font-size: 12px;
  display: block;
  margin-bottom: 16px;
}
.messages .min_wrap .text01 {
  margin-bottom: 72px;
  text-align: center;
  line-height: 1.5;
}
.messages .min_wrap .message_cards .message_card {
  max-width: 360px;
}
.messages .min_wrap .message_cards .message_card.message_card_wide {
  width: 100%;
  display: flex;
  max-width: none;
  margin-bottom: 72px;
}
.messages .min_wrap .message_cards .message_card.message_card_wide .message_card_img {
  max-width: 226px;
}
.messages .min_wrap .message_cards .message_card.message_card_wide .flex {
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #eaeaea;
  height: auto;
}
.messages .min_wrap .message_cards .message_card .flex {
  background: #eaeaea;
  height: 58px;
  align-items: center;
  justify-content: center;
}
.messages .min_wrap .message_cards .message_card .flex .text01 {
  margin: 0;
}
.messages .min_wrap .message_cards .message_card .message_card_img {
  overflow: hidden;
}
.messages .min_wrap .message_cards .message_card .message_card_img img {
  transition: 0.3s;
}
.messages .min_wrap .message_cards .message_card:hover .message_card_img img {
  transform: scale(1.1);
  transition: all 0.5s ease;
}

.message_videos {
  background: linear-gradient(#DFE9EF, #DFE9EF, #ffffff);
  padding: 72px 0;
}
.message_videos .wrapper .flex {
  width: 100%;
  background: #eaeaea;
  justify-content: start;
  width: calc(100% + 198px);
  margin-left: -99px;
  margin-bottom: 36px;
}
.message_videos .wrapper .flex li .text01 {
  display: block;
  padding: 21px 36px;
}
.message_videos .wrapper .massage_video_content {
  border-bottom: 1px solid #eaeaea;
}
.message_videos .wrapper .massage_video_content a {
  padding: 36px 0;
  display: block;
}
.message_videos .wrapper .massage_video_content a .massage_video_desc {
  font-size: 12px;
  margin-bottom: 21px;
}
.message_videos .wrapper .massage_video_content a .massage_video_desc span {
  font-size: 11px;
  color: #939393;
}
.message_videos .wrapper .btm_btns {
  max-width: 285px;
  margin: 0 auto;
  margin-top: 108px;
}
.message_videos .wrapper .btm_btns ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.message_videos .wrapper .btm_btns ul li a {
  color: #171C61;
}
.message_videos .wrapper .btm_btns ul li.button04 a {
  color: #fff;
  width: 100px;
  height: 30px;
  background: #171C61;
  display: flex;
  align-items: center;
  justify-content: center;
}

.news_detail .wrapper .news_detail_nav {
  margin-top: 144px;
  align-items: center;
}
.news_detail .wrapper .news_detail_nav li {
  width: calc(33% - 14px);
  position: relative;
}
.news_detail .wrapper .news_detail_nav li.news_detail_nav_back::before {
  content: url(../img/arrow_back.svg);
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 0;
}
.news_detail .wrapper .news_detail_nav li.news_detail_nav_back a {
  padding-left: 16px;
}
.news_detail .wrapper .news_detail_nav li.news_detail_nav_next::after {
  content: url(../img/arrow_next_black.svg);
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 0;
}
.news_detail .wrapper .news_detail_nav li.news_detail_nav_next a {
  padding-right: 16px;
}
.news_detail .wrapper .news_detail_nav li.back {
  text-align: center;
}
.news_detail .wrapper .news_detail_nav li a {
  display: block;
  color: #2E2E2E;
  font-weight: 200;
  line-height: 1.5;
}
.news_detail .wrapper p + h3 {
  margin-top: 49px;
}

.senior_list {
  background: linear-gradient(#DFE9EF, #DFE9EF, #ffffff);
  padding: 72px 0;
}
.senior_list .wrapper h2 {
  font-size: 28px;
  margin-bottom: 72px;
}
.senior_list .wrapper ul li:not(:last-child) {
  margin-bottom: 32px;
}
.senior_list .wrapper ul li .card03 .card03_desc {
  background: #DFE9EF;
}
.senior_list .wrapper ul li.gray {
  background: #eaeaea;
}
.senior_list .wrapper ul li:nth-child(even) .card03_desc {
  background: #eaeaea;
}

.senior_employee .sec_title02 h2 {
  margin-top: 0;
}
.senior_employee .wrapper .senior_employee_kv {
  background: url(../img/senior_employee_kv_test.jpg) 100% 100%/cover;
  height: 422px;
  width: calc(100% + 198px);
  margin-left: -99px;
  margin-bottom: 72px;
}
.senior_employee .wrapper .senior_employee_wrap {
  display: flex;
  justify-content: space-between;
}
.senior_employee .wrapper .senior_employee_wrap .senior_employee_title {
  font-size: 22px;
  color: #171C61;
}
.senior_employee .wrapper .senior_employee_wrap .senior_employee_inner {
  width: 78%;
}
.senior_employee .wrapper .senior_employee_wrap .senior_employee_inner h4 {
  font-size: 20px;
  color: #171C61;
  margin-bottom: 40px;
  border-bottom: none;
  padding: 0;
  display: block;
}
.senior_employee .wrapper .senior_employee_wrap .senior_employee_inner .text01 {
  margin-bottom: 48px;
}
.senior_employee .wrapper .senior_employee_wrap .senior_employee_inner .text02 {
  margin-bottom: 48px;
}
.senior_employee .wrapper .senior_employee_wrap .senior_employee_inner h5 {
  display: inline-block;
  font-weight: 400;
  border-bottom: 2px solid #171C61;
  margin-bottom: 24px;
  padding: 0 3px 10px;
}
.senior_employee .wrapper .senior_employee_wrap .senior_employee_inner strong {
  color: #171C61;
  font-size: 14px;
  margin-bottom: 24px;
  font-weight: 400;
  display: block;
}

.chronology .wrapper .chronology_wrap strong {
  font-weight: 500;
}
.chronology .wrapper .chronology_wrap li {
  width: 100%;
  margin-bottom: 14px;
  padding: 7px;
  border-bottom: 1px solid rgba(23, 28, 97, 0.1);
}
.chronology .wrapper .chronology_wrap li:last-child {
  margin-bottom: 0;
}
.chronology .wrapper .chronology_wrap li span {
  width: 10%;
}
.chronology .wrapper .chronology_wrap li .tpix {
  width: 46.4%;
  font-size: 14px;
  color: #171C61;
}
.chronology .wrapper .chronology_wrap li .tenpo_tenkai {
  width: 24%;
  font-size: 14px;
  color: #171C61;
}
.chronology .wrapper .chronology_wrap li .day {
  width: 10%;
  font-size: 18px;
}
.chronology .wrapper .chronology_wrap li .tpix_width {
  width: 46.4%;
}
.chronology .wrapper .chronology_wrap li .tenpo_tenkai_width {
  width: 24%;
}
.chronology .wrapper .chronology_wrap li .text01 {
  font-size: 16px;
}

.resident_info .container {
  background: #DFE9EF;
  padding: 4rem 2rem;
  text-align: center;
}
.resident_info .container .text01 {
  margin-top: 2rem;
}
.resident_info .container + .sec_title02 {
  margin-top: 70px;
}

.business_goal {
  background: linear-gradient(#DFE9EF, #DFE9EF, #ffffff);
  padding: 72px 0;
}
.business_goal .wrapper .business_goal_title {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: #171C61;
  border: 1px solid #171C61;
  font-size: 22px;
  height: 72px;
  font-weight: 500;
  margin-bottom: 72px;
}
.business_goal .wrapper .business_goal_sec02 .plus_margin {
  margin-bottom: 72px;
}
.business_goal .wrapper .business_goal_h3 {
  font-size: 22px;
  color: #171C61;
  font-weight: 500;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
}
.business_goal .wrapper .business_goal_h3::before {
  background: #DFE9EF;
  font-size: 32px;
  height: 56px;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 16px;
}
.business_goal .wrapper .business_goal_h3.business_goal_disc_01::before {
  content: "1";
}
.business_goal .wrapper .business_goal_h3.business_goal_disc_02::before {
  content: "2";
}
.business_goal .wrapper .business_goal_h3.business_goal_disc_03::before {
  content: "3";
}
.business_goal .wrapper .business_goal_h3.business_goal_disc_04::before {
  content: "4";
}
.business_goal .wrapper .business_goal_h3.business_goal_disc_05::before {
  content: "5";
}
.business_goal .wrapper .business_goal_h3.business_goal_disc_06::before {
  content: "6";
}
.business_goal .wrapper .business_goal_h3.business_goal_disc_07::before {
  content: "7";
}
.business_goal .wrapper .business_goal_h3.business_goal_disc_08::before {
  content: "8";
}
.business_goal .wrapper .business_goal_h3.business_goal_disc_09::before {
  content: "9";
}

.overview .wrapper dl {
  line-height: 1.5;
}
.overview .wrapper dl dt,
.overview .wrapper dl dd {
  margin-bottom: 72px;
}
.overview .wrapper dl dt:last-child,
.overview .wrapper dl dd:last-child {
  margin-bottom: 0;
}
.overview .wrapper dl dt {
  width: 112px;
}
.overview .wrapper dl dt strong {
  font-weight: 400;
  font-size: 18px;
}
.overview .wrapper dl dd {
  width: calc(100% - 200px);
  font-size: 18px;
}
.overview .wrapper dl.overview_2nd dt,
.overview .wrapper dl.overview_2nd dd {
  display: flex;
  align-items: center;
}
.overview .wrapper dl.overview_2nd dt {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.overview .wrapper dl.overview_2nd dd {
  width: calc(75% - 72px);
}

.board_member_introduction .wrapper .board_member_introduction_content {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 72px;
  align-items: start;
}
.board_member_introduction .wrapper .board_member_introduction_content:last-child {
  margin-bottom: 0;
}
.board_member_introduction .wrapper .board_member_introduction_content .board_member_introduction_img {
  width: 228px;
  object-fit: contain;
}
.board_member_introduction .wrapper .board_member_introduction_content .board_member_introduction_desc {
  padding-left: 77px;
  width: calc(100% - 228px);
}
.board_member_introduction .wrapper .board_member_introduction_content .board_member_introduction_desc h3 {
  color: #171C61;
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 36px;
}
.board_member_introduction .wrapper .board_member_introduction_content .board_member_introduction_desc h3 span {
  display: block;
  font-size: 12px;
  margin-bottom: 16px;
}
.board_member_introduction .wrapper .board_member_introduction_content .board_member_introduction_desc .text01 {
  font-weight: 300;
}

.list_of_bases .wrapper .list_of_bases_f {
  margin-bottom: 72px;
}
.list_of_bases .wrapper .base_links {
  margin-bottom: 144px;
}
.list_of_bases .wrapper .base_links .bases_map {
  max-width: 384px;
}
.list_of_bases .wrapper .base_links .base_links_all {
  width: calc(100% - 384px);
  padding-left: 64px;
}
.list_of_bases .wrapper .base_links .base_links_all a {
  line-height: 2.5;
  color: #2E2E2E;
  font-size: 14px;
}
.list_of_bases .wrapper .base_links .base_links_all a::after {
  content: url(../img/diagonal_line.svg);
  position: relative;
  top: 5px;
  left: 3px;
}
.list_of_bases .wrapper .base_links .base_links_all a:last-child::after {
  content: none;
}
.list_of_bases .wrapper .base_desc_wrap .bases_desc {
  width: 50%;
}
.list_of_bases .wrapper .base_desc_wrap .bases_desc h3 {
  font-size: 22px;
  color: #171C61;
  font-weight: 400;
  margin-bottom: 32px;
}
.list_of_bases .wrapper .base_desc_wrap .bases_desc .button05 {
  margin-top: 36px;
}
.list_of_bases .wrapper .base_desc_wrap .base_img {
  width: calc( 50% - 28px );
}
.list_of_bases .wrapper .base_desc_wrap .base_img img {
  width: 100%;
}
.list_of_bases .wrapper .flex .bases {
  width: calc( 50% - 28px);
  margin-bottom: 4rem;
  display: flex;
  justify-content: space-between;
}
.list_of_bases .wrapper .flex .bases .bases_inner {
  padding-right: 10px;
}
.list_of_bases .wrapper .flex .bases .bases_inner h3 {
  font-size: 22px;
  color: #171C61;
  font-weight: 400;
  margin-bottom: 32px;
}
.list_of_bases .wrapper .flex .bases .bases_inner h3 a {
  color: #171C61;
}
.list_of_bases .wrapper .flex .bases .bases_inner .text01 {
  font-size: 14px;
  margin-bottom: 14px;
}
.list_of_bases .wrapper .flex .bases .base_img {
  width: calc( 50% - 28px );
}
.list_of_bases .wrapper .flex .bases .base_img img {
  width: 100%;
}

.breadcrumb_trail {
  width: 100%;
  padding: 14px 0;
  background: #DFE9EF;
  position: relative;
}
.breadcrumb_trail ul {
  max-width: 1187px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-wrap: wrap;
}
.breadcrumb_trail ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
@media screen and (max-width: 599px) {
  .breadcrumb_trail ul li {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .breadcrumb_trail ul li {
    display: inherit;
    margin: 7px 0;
  }
}
.breadcrumb_trail ul li span,
.breadcrumb_trail ul li a {
  position: relative;
  font-size: 12px;
}
.breadcrumb_trail ul li span {
  color: #2E2E2E;
}
.breadcrumb_trail ul li a {
  color: #939393;
  transition: all 0.5s ease;
}
.breadcrumb_trail ul li a:hover {
  color: #000;
}
.breadcrumb_trail ul li::after {
  content: url(../img/diagonal_line_w.svg);
  position: relative;
  margin: 0 7px;
}
.breadcrumb_trail ul li:last-child::after {
  content: none;
}

footer {
  background: #171C61;
  transition: 0.3s;
  position: relative;
}
footer.nav_open {
  transform: translate3d(-100%, 0, 0);
  transition: 0.3s;
}
footer .wrapper {
  background: #171C61;
  padding-top: 70px;
  padding-bottom: 70px;
}
footer .wrapper .footer_wrap_left .footer_logo {
  margin-bottom: 35px;
}
footer .wrapper .footer_wrap_left .text03 {
  color: #fff;
  font-size: 12px;
  line-height: 1.5;
}
footer .wrapper .footer_wrap_right {
  text-align: right;
  width: 42rem;
  font-size: 14px;
}
footer .wrapper .footer_wrap_right .footer_wrap_right_inner {
  margin-bottom: 28px;
}
footer .wrapper .footer_wrap_right .footer_wrap_right_inner a {
  color: #fff;
  line-height: 2.5;
  margin-left: 1rem;
  display: inline-block;
}
footer .wrapper .footer_wrap_right .footer_wrap_right_sns {
  margin-bottom: 42px;
  align-items: center;
  justify-content: end;
}
footer .wrapper .footer_wrap_right .footer_wrap_right_sns p {
  color: #fff;
  font-weight: 400;
}
footer .wrapper .footer_wrap_right .footer_wrap_right_sns .footer_sns {
  margin-left: 28px;
}
footer .wrapper .footer_wrap_right small {
  color: #fff;
  font-size: 11px;
  display: block;
  text-align: right;
}

form {
  background: #eaeaea;
  padding: 72px;
}
form dl {
  max-width: 730px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
}
form dl dt,
form dl dd {
  margin-bottom: 28px;
}
form dl dt {
  width: 12rem;
}
form dl dt .red {
  font-size: 12px;
}
form dl dd {
  width: calc(100% - 12rem - 2rem);
}
form dl input,
form dl select,
form dl textarea {
  border: none;
  width: 100%;
}
form dl input,
form dl select {
  padding: 0.75rem 1rem;
}
form dl textarea {
  height: 14rem;
}

input[type=submit] {
  background: #171C61;
  color: #fff;
  margin: 0 auto;
  padding: 1.25rem;
  width: calc( 100% - 35.125rem );
  border: none;
  margin: auto;
  display: block;
  cursor: pointer;
  transition: all 0.5s ease;
}
input[type=submit]:hover {
  background: #12164c;
}

.wpcf7 form .wpcf7-not-valid-tip {
  color: #E35A5A;
  font-size: 13px;
  margin-top: 0.25rem;
}

.wpcf7 form .wpcf7-response-output {
  text-align: center;
  font-size: 14px;
  background: #fff;
  padding: 0.5rem 1rem !important;
}

.inquiry_link {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  color: #2E2E2E;
}
.inquiry_link:before {
  content: "";
  background: #E7E7EF url(../img/arrow_next_blue.svg) no-repeat 50% 50%;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

.target .wrapper .target_inner .target_content_full {
  width: 100%;
}
.target .wrapper .target_inner .target_content_half {
  width: calc( 50% - 28px );
  text-align: center;
}
.target .wrapper .target_inner .target_content_half h3 {
  font-size: 22px;
  color: #171C61;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 40px;
}
.target .wrapper .target_inner .target_content_half .graph {
  max-width: 255px;
  margin-bottom: 40px;
}
.target .wrapper .target_inner .target_content_half .text01 {
  font-size: 14px;
}

.activity_reports .wrapper .business_goal_disc h3 {
  margin-bottom: 0;
}
.activity_reports .wrapper .business_goal_disc h3 + .sec_title02_days {
  margin-top: 0.5rem;
}
.activity_reports .wrapper .activity_report_wrap article {
  margin-bottom: 32px;
}
.activity_reports .wrapper .activity_report_wrap article a {
  align-items: center;
  justify-content: flex-start;
  color: #000;
}
.activity_reports .wrapper .activity_report_wrap article a > img {
  max-width: 292px;
}
.activity_reports .wrapper .activity_report_wrap article a > img + * {
  margin-left: 4rem;
}
.activity_reports .wrapper .activity_report_wrap article a .activity_report_desc > * {
  margin: 0 0 2rem;
}

.activity_report .wrapper .activity_report_img_30p {
  display: flex;
  margin-bottom: 48px;
}
.activity_report .wrapper .activity_report_img_30p img {
  width: 30%;
}
.activity_report .wrapper .sec_title02:not(:first-child) {
  margin-top: 70px;
}
.activity_report .wrapper .text01 + .text01 {
  margin-top: 2rem;
}

.home .kv + main {
  padding-top: 0;
}
.home .news {
  padding-top: 114px;
  padding-bottom: 72px;
}

.contact .content ul {
  margin-bottom: 70px;
}

.cmn-pagenav {
  display: flex;
  justify-content: center;
  margin-top: 72px;
}
.cmn-pagenav__item {
  line-height: 32px;
  text-align: center;
  margin: 0 6px;
  width: 2rem;
  height: 32px;
}
.cmn-pagenav__item.is_current {
  background: #171C61;
  color: white;
}
.cmn-pagenav__item.is_prev a, .cmn-pagenav__item.is_next a {
  color: #171C61;
  background-repeat: no-repeat;
  background-size: 15px 15px;
  width: 20px;
  height: 32px;
  transform: translateY(-2px);
}
.cmn-pagenav__item.is_prev a::before, .cmn-pagenav__item.is_next a::before {
  content: none;
}
.cmn-pagenav__item.is_prev a {
  background-image: url(../img/ico/ico_arrow06.png);
  background-position: left center;
}
.cmn-pagenav__item.is_next a {
  background-image: url(../img/ico/ico_arrow07.png);
  background-position: right center;
}
.cmn-pagenav__item a {
  display: block;
  position: relative;
  color: #171C61;
  transition: all 0.5s ease;
}
.cmn-pagenav__item a::before {
  background: #7490CE;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  opacity: 0;
  transition: all 0.5s ease;
}
.cmn-pagenav__item a:hover::before {
  opacity: 1;
}

.photobox > * {
  width: 100%;
  margin-bottom: 2rem;
}

.title_fourten {
  display: block;
  padding: 0.75rem 1rem;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  border: 2px solid #171C61;
  color: #171C61;
}

.nav_bottom.wrapper {
  background: transparent;
  padding-bottom: 0;
}
.nav_bottom.wrapper .card06 {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .onlysp {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .onlypc {
    display: none;
  }
}

body.noscroll {
  overflow: hidden;
}

.nav_sp {
  position: fixed;
  z-index: 5;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #fff;
  padding-top: 100px;
  display: none;
  overflow: hidden;
  overflow-y: scroll;
}
.nav_sp ul li a {
  padding: 25px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #171C61;
  border-bottom: 1px solid #f2f2f2;
  font-weight: 400;
}
.nav_sp ul li.header_nav_li .nav_toggle {
  height: 28px;
  width: 28px;
  position: relative;
}
.nav_sp ul li.header_nav_li .nav_toggle span {
  height: 1px;
  width: 50%;
  background: #171C61;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.nav_sp ul li.header_nav_li .nav_toggle span.nav_toggleline02 {
  transform: rotate(90deg);
  transition: 0.3s;
}
.nav_sp ul li.header_nav_li .nav_toggle.nav_open span.nav_toggleline02 {
  transform: none;
  transition: 0.3s;
}
.nav_sp ul li .header_nav_big {
  display: none;
}
.nav_sp .plus_arrow a:after,
.nav_sp .header_nav_big_link a:after {
  content: "";
  background: url(../img/arrow_next_blue.svg) no-repeat 50% 50%;
  height: 14px;
  width: 14px;
}

.menu_bar {
  position: fixed;
  right: 20px;
  height: 16px;
  width: 24px;
  flex-wrap: wrap;
  justify-content: space-between;
  top: 50%;
  transform: translateY(-50%);
  display: none;
}
.menu_bar .menu_line {
  background: #000;
  height: 1px;
  width: 100%;
  position: absolute;
  transition: 0.3s;
}
.menu_bar .menu_line.line_top {
  top: 0;
}
.menu_bar .menu_line.line_mid {
  top: 50%;
  transform: translateY(-50%);
}
.menu_bar .menu_line.line_bot {
  bottom: 0;
}
.menu_bar.menu_bar_open .menu_line {
  transition: 0.3s;
}
.menu_bar.menu_bar_open .menu_line.line_top {
  top: 8px;
  transform: rotate(45deg);
}
.menu_bar.menu_bar_open .menu_line.line_mid {
  opacity: 0;
}
.menu_bar.menu_bar_open .menu_line.line_bot {
  transform: rotate(-45deg);
  bottom: 7px;
}
.menu_bar.on_shadow {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
}

@media (max-width: 1186px) {
  header {
    width: calc(100% - 28px);
  }

  .wrapper {
    width: calc(100% - 30px);
  }

  .breadcrumb_trail ul {
    padding-left: 14px;
    padding-right: 14px;
  }
}
@media (max-width: 950px) {
  header {
    border-radius: 10px;
  }
  header .header_nav {
    display: none;
  }
  header .menu_bar {
    display: flex;
  }

  .list_of_bases .wrapper .content .base_links {
    display: block;
    margin-bottom: 72px;
  }
  .list_of_bases .wrapper .content .base_links .bases_map {
    max-width: 100%;
  }
  .list_of_bases .wrapper .content .base_links .base_links_all {
    width: 100%;
    padding: 0;
  }
  .list_of_bases .wrapper .content .base_desc_wrap {
    display: block;
  }
  .list_of_bases .wrapper .content .base_desc_wrap .bases_desc {
    width: 100%;
  }
  .list_of_bases .wrapper .content .base_desc_wrap .base_img {
    width: 100%;
  }
}
@media (max-width: 1056px) {
  .sec_title {
    margin-left: 14px;
    margin-right: 14px;
  }

  footer .wrapper {
    display: block;
    padding: 48px 15px;
  }
  footer .wrapper .footer_wrap_left {
    text-align: center;
    margin: 0 auto;
    margin-bottom: 35px;
  }
  footer .wrapper .footer_wrap_right {
    text-align: left;
    width: auto;
  }
  footer .wrapper .footer_wrap_right .footer_wrap_right_inner {
    margin-left: -1rem;
  }

  main > section {
    padding-right: 14px;
    padding-left: 14px;
  }

  .recruit {
    padding-left: 0;
    padding-right: 0;
  }
  .recruit .sec_title {
    padding-left: 14px;
  }
}
@media screen and (max-width: 767px) {
  p, ul li, dl dt, dl dd {
    font-size: 14px;
    line-height: 1.75;
  }

  .card05 > li,
.card05 .card_content,
.card06 > li,
.card06 .card_content {
    width: 100%;
    margin: 0 0 14px;
  }

  .card05 .card_content, .card06 .card_content {
    width: 100%;
    margin: 0 0px 28px;
  }
  .card05 .card_content .card_txt p, .card06 .card_content .card_txt p {
    font-size: 12px;
  }

  .menu_bar {
    display: block;
  }

  header {
    top: 15px;
    height: 60px;
  }
  header .header_nav_logo {
    max-width: 108px;
  }
  header .header_nav {
    display: none;
  }
  header .menu_bar {
    display: flex;
  }

  main > section {
    padding: 35px 14px;
  }
  main > section .wrapper {
    padding: 35px 21px 70px;
    width: calc(100% - 30px);
  }
  main > section .wrapper .sec_title02:before {
    left: -26px;
    width: 21px;
    overflow: hidden;
  }
  main > section .wrapper .sec_title02 .sec_title02_title {
    margin-top: 18px;
  }

  .news_detail .wrapper .news_detail_nav {
    margin-top: 72px;
  }

  .about {
    padding-bottom: 72px;
  }
  .about .about_contents .button02_ul {
    display: block;
  }
  .about .about_contents .button02_ul .button02 {
    margin: 0 auto 32px;
  }

  .recruit {
    padding: 32px 0 0;
  }
  .recruit .sec_title {
    padding: 0 15px;
  }
  .recruit .recruit_content .recruit_content_desc {
    width: 100%;
    order: 1;
    padding: 48px 0 72px;
  }
  .recruit .recruit_content .recruit_content_img {
    width: 100%;
    order: 2;
  }

  .list_of_bases .wrapper .flex .bases {
    width: 100%;
    flex-wrap: wrap;
  }
  .list_of_bases .wrapper .flex .bases .bases_inner {
    margin-bottom: 32px;
  }
  .list_of_bases .wrapper .flex .bases .base_img {
    width: 100%;
  }

  .resident_info .container {
    padding: 1rem;
  }

  .text01 {
    font-size: 14px;
  }

  .wrapper .content > * {
    margin-bottom: 56px;
  }

  footer .wrapper .footer_wrap_right .footer_wrap_right_sns {
    justify-content: center;
    margin-bottom: 35px;
  }
  footer .wrapper .footer_wrap_right small {
    text-align: center;
    line-height: 1.5;
  }

  main {
    padding: 70px 0;
  }

  .sec_title h1 {
    font-size: 21px;
  }
  .sec_title h1 .sec_title_sub01 {
    font-size: 11px;
  }

  h2.sec_title02, .sec_title02 h2 {
    font-size: 21px;
    margin-bottom: 35px;
  }

  div.sec_title02 {
    margin-bottom: 35px;
  }

  h3 {
    font-size: 19.25px;
    margin-bottom: 21px;
  }

  .nav_title01 {
    margin-bottom: 35px;
  }

  .sec_title {
    margin-bottom: 35px;
    padding-left: 15px;
  }
  .sec_title span {
    margin-bottom: 7px;
  }

  .home .kv .kv_guidance {
    width: calc(100% - 70px);
    left: 35px;
    bottom: 35px;
  }
  .home .kv .kv_guidance h1 {
    font-size: 28px;
  }
  .home .recruit .recruit_content .recruit_content_desc .recruit_content_desc_inner span {
    margin-bottom: 21px;
  }
  .home .about .about_contents .about_fir img {
    width: 49px;
    margin-right: 7px;
  }
  .home .about .about_contents h1 {
    font-size: 28px;
  }
  .home .about .about_contents .button02_ul .button02 {
    margin-bottom: 21px;
    max-width: none;
  }
  .home .messages {
    padding: 70px 14px 0;
  }
  .home .messages .min_wrap h2 {
    margin-bottom: 42px;
  }
  .home .messages .min_wrap .message_cards .message_card {
    margin-bottom: 42px;
    max-width: none;
  }
  .home .messages .min_wrap .message_cards .message_card.message_card_wide {
    display: block;
  }
  .home .messages .min_wrap .message_cards .message_card.message_card_wide .message_card_img {
    max-width: none;
  }
  .home .messages .min_wrap .message_cards .message_card.message_card_wide .message_card_img img {
    width: 100%;
  }
  .home .messages .min_wrap .message_cards .message_card.message_card_wide .flex {
    height: 56px;
  }

  .business_goal_sec01 .business_goal_img,
.business_goal_sec01 .business_goal_disc {
    width: 100%;
  }
  .business_goal_sec01 .business_goal_img:not(:last-child),
.business_goal_sec01 .business_goal_disc:not(:last-child) {
    margin-bottom: 42px;
  }

  .target .wrapper .target_inner .target_content_half {
    width: 100%;
  }
  .target .wrapper .target_inner .target_content_half:not(:last-child) {
    margin-bottom: 42px;
  }
  .target .wrapper .target_inner .target_content_half h3 {
    font-size: 17.5px;
  }

  .business_goal_sec01 {
    margin-bottom: 0;
  }

  .overview .wrapper dl, .overview .wrapper .overview_2nd {
    display: block;
  }
  .overview .wrapper dl dt, .overview .wrapper dl dd, .overview .wrapper .overview_2nd dt, .overview .wrapper .overview_2nd dd {
    width: 100% !important;
    font-size: 14px;
  }
  .overview .wrapper dl dt, .overview .wrapper .overview_2nd dt {
    margin-bottom: 14px;
    font-weight: 400;
  }
  .overview .wrapper dl dd, .overview .wrapper .overview_2nd dd {
    display: block !important;
  }
  .overview .wrapper dl dd:not(:last-child), .overview .wrapper .overview_2nd dd:not(:last-child) {
    margin-bottom: 42px;
  }
  .overview .wrapper .overview_2nd dd {
    text-align: center;
  }

  .chronology .chronology_wrap {
    display: block;
  }
  .chronology .chronology_wrap .flex > * {
    width: 100% !important;
  }
  .chronology .chronology_wrap .flex span, .chronology .chronology_wrap .flex .tpix, .chronology .chronology_wrap .flex .tenpo_tenkai {
    display: none;
  }
  .chronology .chronology_wrap .flex .day, .chronology .chronology_wrap .flex .text01 {
    font-size: 15.75px !important;
  }
  .chronology .chronology_wrap .flex .day {
    margin-bottom: 7px;
  }

  .board_member_introduction_content {
    display: block !important;
  }
  .board_member_introduction_content .board_member_introduction_img, .board_member_introduction_content .board_member_introduction_desc {
    width: 100% !important;
    padding: 0 !important;
  }

  .filter {
    width: 100%;
    margin: 0;
  }
  .filter > * {
    width: 100%;
  }
  .filter > *:first-child {
    display: none;
  }

  .news_detail_nav {
    flex-wrap: wrap;
    font-size: 14px;
  }
  .news_detail_nav > * {
    width: 100% !important;
    margin-bottom: 21px;
  }
  .news_detail_nav .back {
    order: 3;
  }

  form {
    padding: 21px;
  }
  form dl {
    display: block;
  }
  form dl > * {
    width: 100% !important;
  }
  form dl dt {
    margin-bottom: 7px;
  }
  form dl dd {
    margin-bottom: 21px;
  }
  form input[type=submit] {
    width: 100%;
    max-width: 300px;
  }

  .senior_employee .wrapper .senior_employee_kv {
    width: calc(100% + 42px);
    margin-left: -21px;
    max-height: 200px;
  }
  .senior_employee .wrapper .senior_employee_wrap {
    flex-wrap: wrap;
  }
  .senior_employee .wrapper .senior_employee_wrap .senior_employee_title {
    width: 100%;
  }
  .senior_employee .wrapper .senior_employee_wrap .senior_employee_inner {
    width: 100%;
  }
}
@media screen and (max-width: 599px) {
  .wrapper {
    width: 100% !important;
  }

  .senior_list {
    padding: 48px 0;
  }

  .sec_title02 {
    margin-bottom: 32px;
  }
  .sec_title02 h2::before {
    width: 21px;
    overflow: hidden;
    left: -25px;
  }

  .card03 .card03_profimg {
    width: 100%;
  }
  .card03 .card03_profimg img {
    width: 100%;
  }
  .card03 .card03_desc {
    width: 100%;
    padding: 24px 16px 8px;
  }

  .activity_report .wrapper .activity_report_img_30p img {
    width: 100%;
  }
  .activity_report .wrapper .activity_report_img_30p img:not(:last-child) {
    margin-bottom: 21px;
  }
}